import React from "react";
import Modal from "react-modal";

const ConfirmDeleteModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="relative p-6 w-full max-w-md mx-auto mt-20 bg-white rounded-lg shadow-lg focus:outline-none raleway-regular"
      overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75 z-50 flex items-center justify-center"
    >
      <div className="text-center">
        <h2 className="text-xl font-semibold text-gray-800">
          Are you sure you want to delete this?
        </h2>
        <p className="mt-2 text-gray-600">This action cannot be undone.</p>
        <div className="mt-6 flex justify-center space-x-4">
          <button
            onClick={onConfirm}
            className="bg-white text-red-500 py-2 px-4 border border-red-500 rounded focus:outline-none focus:ring-2 focus:ring-red-500 hover:bg-red-500 hover:text-white transition duration-300 ease-in-out"
          >
            Yes, Delete
          </button>
          <button
            onClick={onClose}
            className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
