import React, { useState, useEffect, useContext } from "react";
import Header from "../components/Header";
import AddEmployeeModal from "../components/AddEmployeeModal";
import LimitWarningModal from "../components/LimitWarningModal";
import axios from "axios";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import { AuthContext } from "../AuthContext";

const ManageUsers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLimitWarningOpen, setIsLimitWarningOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userData } = useContext(AuthContext);
  const [employeeLimit, setEmployeeLimit] = useState(null);

  useEffect(() => {
    let currentUserData = userData();
    if (!currentUserData) {
      currentUserData = JSON.parse(localStorage.getItem("userData"));
    }

    const limit =
      currentUserData?.company_employees_limit ||
      JSON.parse(localStorage.getItem("userData"))?.company_employees_limit;

    setEmployeeLimit(limit);
  }, []);

  const allUsers = async () => {
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;

      if (!token) {
        console.error("Access token not found");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/user/employees/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data) {
        setUsers(response?.data?.employees);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch users. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    allUsers();
  }, []);

  const toggleUserStatus = async (email, currentStatus) => {
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;

      if (!token) {
        toast.error("Access token not found");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/active-status/`,
        { email: email, is_active: !currentStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.status) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.email === email ? { ...user, is_active: !currentStatus } : user
          )
        );
        toast.success(response.data.detail || "User status updated!");
      }
    } catch (error) {
      toast.error("Failed to update status. Please try again.");
    }
  };

  const openAddEmployeeModal = () => {
    // Check if the limit is exceeded
    if (users.length >= employeeLimit) {
      setIsLimitWarningOpen(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const closeAddEmployeeModal = () => setIsModalOpen(false);
  const closeLimitWarningModal = () => setIsLimitWarningOpen(false);

  return (
    <div className="raleway-regular">
      <Header classname="z-50" />
      <div className="container mx-auto p-4">
        <div className="flex justify-end mb-4">
          <button
            onClick={openAddEmployeeModal}
            className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
          >
            Add Employee
          </button>
        </div>

        {/* AddEmployeeModal */}
        <AddEmployeeModal
          isOpen={isModalOpen}
          closeModal={closeAddEmployeeModal}
          allUsers={allUsers}
        />

        {/* LimitWarningModal */}
        <LimitWarningModal
          isOpen={isLimitWarningOpen}
          closeModal={closeLimitWarningModal}
        />

        {/* Loader */}
        {loading ? (
          <div className="flex justify-center items-center p-4">
            <BeatLoader />
          </div>
        ) : (
          <div className="bg-white shadow-md rounded my-6 w-full max-w-[90vw] lg:max-w-[80vw] mx-auto overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-3 px-6 bg-gray-200 font-bold text-gray-600 uppercase text-sm text-left">
                    Id
                  </th>
                  <th className="py-3 px-6 bg-gray-200 font-bold text-gray-600 uppercase text-sm text-left">
                    Name
                  </th>
                  <th className="py-3 px-6 bg-gray-200 font-bold text-gray-600 uppercase text-sm text-left">
                    Email
                  </th>
                  <th className="py-3 px-6 bg-gray-200 font-bold text-gray-600 uppercase text-sm text-left">
                    Last Login
                  </th>
                  <th className="py-3 px-6 bg-gray-200 font-bold text-gray-600 uppercase text-sm text-left">
                    Uploads
                  </th>
                  <th className="py-3 px-6 bg-gray-200 font-bold text-gray-600 uppercase text-sm text-left">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user) => (
                  <tr
                    key={user.id}
                    className="border-b border-gray-200 hover:bg-gray-100"
                  >
                    <td className="py-3 px-6 text-left">{user.id}</td>
                    <td className="py-3 px-6 text-left">
                      {user.first_name + " " + user?.last_name}
                    </td>
                    <td className="py-3 px-6 text-left">{user?.email}</td>
                    <td className="py-3 px-6 text-left">
                      {user.last_login === null ? "N/A" : user?.last_login}
                    </td>
                    <td className="py-3 px-6 text-left">
                      {user.total_uploads}
                    </td>
                    <td className="py-3 px-6 text-left">
                      <button
                        onClick={() =>
                          toggleUserStatus(user.email, user.is_active)
                        }
                        className={`relative inline-flex items-center px-1 py-1 rounded-full w-24 h-7 transition-colors duration-300 ${
                          user.is_active ? "bg-green-500" : "bg-red-500"
                        }`}
                      >
                        <span
                          className={`absolute inline-block w-6 h-5 bg-white rounded-full shadow-md transform transition-transform duration-300 ${
                            user.is_active ? "translate-x-14" : "translate-x-1"
                          }`}
                        />
                        <span
                          className={`text-white text-sm font-medium ${
                            user?.is_active ? "ml-3" : "ml-8"
                          }`}
                        >
                          {user.is_active ? "Active" : "Inactive"}
                        </span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManageUsers;
