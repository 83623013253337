import React, { useState, useEffect, useRef, useContext } from "react";
import logo from "../assets/2.png";
import coinLogo from "../assets/coin.png";
import { Link, useNavigate } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { IoNotificationsOutline } from "react-icons/io5";
import { FaChevronDown, FaBars, FaTimes } from "react-icons/fa";
import Notifications from "./Notifications";
import { AuthContext } from "../AuthContext";
import toast from "react-hot-toast";
import { IoPersonSharp } from "react-icons/io5";

const Header = ({ classname = "" }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState([
    "Notification 1",
    "Notification 2",
    "Notification 3",
  ]);

  const { logout, isCompanyOwner, credits } = useContext(AuthContext);

  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     await userData();
  //     const credits = JSON.parse(
  //       localStorage.getItem("userData")
  //     )?.credits_available;
  //     setCredits(credits);
  //   };

  //   fetchUserData();
  // }, [userData]);

  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const handleNotificationsToggle = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const handleDeleteNotification = (index) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((_, i) => i !== index)
    );
  };

  const handleDeleteAllNotifications = () => {
    setNotifications([]);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    logout();
    toast.success("Logout Successfully");
    navigate("/login");
  };

  return (
    <div
      className={`bg-[#191D55] h-16 flex justify-between items-center px-5 md:px-20 shadow rounded rounded-t-none raleway-regular ${classname}`}
      style={{
        position: "sticky",
        top: "0",
        ...(dropdownOpen || drawerOpen ? { zIndex: 100 } : {}),
      }}
    >
      <div className="flex items-center gap-5">
        <button className="md:hidden mr-2" onClick={handleDrawerToggle}>
          {drawerOpen ? (
            <FaTimes color="white" size={24} />
          ) : (
            <FaBars color="white" size={24} />
          )}
        </button>
        <Link to={"/"} className="flex items-center">
          <img src={logo} alt="Logo" className="w-24" />
        </Link>
        <Link
          to={"/"}
          title="Dashboard"
          className="md:flex md:items-center hidden"
        >
          <LuLayoutDashboard size={30} color="white" />
        </Link>
      </div>
      <div className="hidden md:flex gap-5 items-center z-50">
        <div className="flex items-center text-white gap-2">
          <img src={coinLogo} alt="Coin Logo" className="w-10" />
          <span>
            <span className="p-1 raleway-regular">{credits}</span>
            Credits!
          </span>
        </div>
        <div className="relative flex items-center z-50" ref={dropdownRef}>
          <IoPersonSharp
            onClick={handleDropdownToggle}
            className="w-10 h-10 rounded-full p-1 cursor-pointer border-2 border-slate-300"
            size={10}
            color="white"
          />
          <FaChevronDown
            className="text-white ml-2 cursor-pointer"
            onClick={handleDropdownToggle}
          />
          {dropdownOpen && (
            <div className="absolute right-0 top-9 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-[100]">
              <Link
                to="/my-account"
                className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                My Account
              </Link>
              {isCompanyOwner && (
                <>
                  <Link
                    to="/company-settings"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Company Settings
                  </Link>
                  <Link
                    to="/manage-users"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Manage Users
                  </Link>
                </>
              )}
              {!isCompanyOwner && (
                <Link
                  to="/add-company"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Convert to Company
                </Link>
              )}

              <Link
                to="/my-subscription"
                className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                My Subscription
              </Link>
              <Link
                to="/activities"
                className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                Activities
              </Link>
              <button
                onClick={handleLogout}
                className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
      {drawerOpen && (
        <div className="absolute top-0 left-0 w-full h-screen bg-[#383B43] z-40 flex flex-col items-center md:hidden">
          <div className="flex justify-end w-full p-4">
            <button onClick={handleDrawerToggle}>
              <FaTimes color="white" size={24} />
            </button>
          </div>
          <div className="flex flex-col items-center w-full px-4 space-y-6">
            <Link
              to={"/"}
              title="Dashboard"
              className="flex items-center mb-5"
              onClick={handleDrawerToggle}
            >
              <LuLayoutDashboard size={30} color="white" />
              <span className="text-white ml-2">Dashboard</span>
            </Link>
            <div className="flex items-center text-white gap-2 mb-5">
              <img src={coinLogo} alt="Coin Logo" className="w-10" />
              <span>{credits} Credits!</span>
            </div>
            <div
              className="flex flex-col items-center relative w-full"
              ref={dropdownRef}
            >
              <div
                className="flex items-center cursor-pointer"
                onClick={handleDropdownToggle}
              >
                <IoPersonSharp
                  className="w-10 h-10 rounded-full p-1 cursor-pointer border-2 border-slate-300"
                  color="white"
                />
                <FaChevronDown className="text-white ml-2" />
              </div>
              {dropdownOpen && (
                <div className="absolute top-12 mt-2 w-48 bg-white rounded-md shadow-lg py-2 z-50">
                  <Link
                    to="/my-account"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    onClick={handleDrawerToggle}
                  >
                    My Account
                  </Link>
                  {isCompanyOwner && (
                    <>
                      <Link
                        to="/company-settings"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        Company Settings
                      </Link>
                      <Link
                        to="/manage-users"
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                      >
                        Manage Users
                      </Link>
                    </>
                  )}
                  {!isCompanyOwner && (
                    <Link
                      to="/add-company"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      Convert to Company
                    </Link>
                  )}
                  <Link
                    to="/my-subscription"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    onClick={handleDrawerToggle}
                  >
                    My Subscription
                  </Link>
                  <Link
                    to="/activities"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    onClick={handleDrawerToggle}
                  >
                    Activities
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Notifications
        isOpen={isNotificationsOpen}
        closeModal={handleNotificationsToggle}
        notifications={notifications}
        onDelete={handleDeleteNotification}
        onDeleteAll={handleDeleteAllNotifications}
      />
    </div>
  );
};

export default Header;
