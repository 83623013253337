import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";

const PageNotFound = () => {
  const [countdown, setCountdown] = useState(5);
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (countdown === 0) {
      isLoggedIn ? navigate("/") : navigate("/login");
    }
    const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    return () => clearTimeout(timer);
  }, [countdown, navigate]);

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-100 text-gray-800 relative raleway-regular">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-red-600 mb-4 animate-bounce">
          404!
        </h1>
        <h2 className="text-2xl font-semibold mb-4">Page Not Found</h2>
        <p className="text-lg mb-8">
          Sorry, the page you are looking for does not exist.
        </p>
        <Link
          to="/"
          className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition duration-300"
        >
          Go to Home
        </Link>
      </div>
      <div className="absolute bottom-4 right-4 bg-white text-gray-800 p-3 rounded-lg shadow-lg animate-fade-in-up">
        <p className="text-lg">
          Redirecting you to the home page in{" "}
          <span className="font-bold">{countdown}</span> seconds...
        </p>
      </div>
    </div>
  );
};

export default PageNotFound;
