import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import NewFolderModal from "./NewFolderModal";
import { IoMdAdd } from "react-icons/io";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import toast from "react-hot-toast";
import { AuthContext } from "../AuthContext";
import axios from "axios";
import FilePreviewModal from "./FilePreviewModal";
import { RxCross2 } from "react-icons/rx";
import FolderTreeModal from "./FolderTreeModal";
import { LuMinusCircle } from "react-icons/lu";
import { IoMdCloudUpload } from "react-icons/io";

const UploadModal = ({
  isUploadModalOpen,
  closeUploadModal,
  files,
  setFiles,
}) => {
  const [selectedFiles, setSelectedFiles] = useState({});
  const { fetchData, userData, fetchFileStatus } = useContext(AuthContext);
  const [globalDocumentType, setGlobalDocumentType] = useState("");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewModalContent, setPreviewModalContent] = useState(null);
  const [folders, setFolders] = useState([]);
  const [isNewFolderModalOpen, setIsNewFolderModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");

  // State for tracking upload progress and status
  const [uploadProgress, setUploadProgress] = useState({});

  // State for handling per-file validation errors
  const [validationErrors, setValidationErrors] = useState({});

  // State for bulk upload limit
  const [bulkUploadLimit, setBulkUploadLimit] = useState(null);
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);

  const [fileList, setFileList] = useState(files);

  useEffect(() => {
    setFileList(files);

    const initialSelectedFiles = {};
    files.forEach((file, index) => {
      initialSelectedFiles[index] = {
        selected: true,
        type: globalDocumentType || "",
      };
    });
    setSelectedFiles(initialSelectedFiles);
  }, []);

  useEffect(() => {
    setFileList(files);
  }, [files]);

  // Load folders from localStorage
  useEffect(() => {
    const storedFolders = JSON.parse(localStorage.getItem("folders")) || [];
    setFolders(storedFolders);
  }, [fetchData]);

  // Fetch bulk_upload_limit when modal opens
  useEffect(() => {
    if (isUploadModalOpen) {
      // Retrieve userData from context or localStorage
      let currentUserData = userData();
      if (!currentUserData) {
        currentUserData = JSON.parse(localStorage.getItem("userData"));
      }

      const limit =
        currentUserData?.plan_details?.bulk_upload_limit ||
        JSON.parse(localStorage.getItem("userData"))?.plan_details
          ?.bulk_upload_limit ||
        process.env.NODE_ENV === "development"
          ? 15
          : 10;

      setBulkUploadLimit(limit);

      if (files.length > limit) {
        setIsLimitExceeded(true);
        toast.error(
          `You can upload up to ${limit} files at a time. Upgrade your plan to upload more files at once.`,
          {
            duration: 5000,
          }
        );
      } else {
        setIsLimitExceeded(false);
      }
    } else {
      // Reset states when modal is closed
      setBulkUploadLimit(null);
      setIsLimitExceeded(false);
    }
  }, [isUploadModalOpen, files, userData]);

  // Handle global document type change
  useEffect(() => {
    if (globalDocumentType) {
      setSelectedFiles((prevSelectedFiles) => {
        const updatedFiles = { ...prevSelectedFiles };
        Object.keys(updatedFiles).forEach((key) => {
          if (updatedFiles[key].selected) {
            updatedFiles[key] = {
              ...updatedFiles[key],
              type: globalDocumentType,
            };
            // Clear validation error if any
            if (validationErrors[key]) {
              setValidationErrors((prev) => {
                const newErrors = { ...prev };
                delete newErrors[key];
                return newErrors;
              });
            }
          }
        });
        return updatedFiles;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalDocumentType]);

  // Handle preview modal open
  const handlePreviewClick = (file) => {
    setPreviewModalContent({
      fileLink: URL.createObjectURL(file),
      format: file.type.split("/")[1],
      name: file.name,
    });
    setIsPreviewModalOpen(true);
  };

  // Close preview modal
  const closePreviewModal = () => {
    setIsPreviewModalOpen(false);
    setPreviewModalContent(null);
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    const newSelectedFiles = {};
    const newValidationErrors = {};

    files.forEach((file, index) => {
      newSelectedFiles[index] = {
        selected: checked,
        type: checked ? globalDocumentType : "",
      };
      // If selecting all, validate the type
      if (checked && !globalDocumentType) {
        newValidationErrors[index] = true;
      }
    });
    setSelectedFiles(newSelectedFiles);
    setValidationErrors(newValidationErrors);
  };

  const handleFileSelect = (index) => {
    setSelectedFiles((prevSelectedFiles) => ({
      ...prevSelectedFiles,
      [index]: {
        ...prevSelectedFiles[index],
        selected: !prevSelectedFiles[index]?.selected,
      },
    }));

    // If deselecting a file, remove any validation error
    if (selectedFiles[index]?.selected) {
      setValidationErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[index];
        return newErrors;
      });
    }
  };

  const handleGlobalDocumentTypeChange = (event) => {
    setGlobalDocumentType(event.target.value);
  };

  const openNewFolderModal = () => {
    setIsNewFolderModalOpen(true);
  };

  const closeNewFolderModal = () => {
    setIsNewFolderModalOpen(false);
  };

  const handleFolderChange = (event) => {
    setSelectedFolder(event.target.value);
  };

  function formatFileSize(sizeInBytes) {
    if (sizeInBytes < 1024) return `${sizeInBytes} bytes`;
    else if (sizeInBytes < 1024 * 1024)
      return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    else if (sizeInBytes < 1024 * 1024 * 1024)
      return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
    else return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
  }

  const handleUpload = async () => {
    if (isLimitExceeded) {
      toast.error(
        `You can upload up to ${bulkUploadLimit} files at a time. Upgrade your plan to upload more files at once.`,
        {
          duration: 5000,
        }
      );
      return;
    }

    setIsLoading(true);
    setValidationErrors({}); // Reset any previous validation errors
    const token = JSON.parse(localStorage.getItem("auth"))?.access;
    const ownerId = JSON.parse(localStorage.getItem("auth"))?.user?.id;

    // Gather selected files
    const selectedFileEntries = Object.entries(selectedFiles).filter(
      ([, fileDetails]) => fileDetails.selected
    );

    if (selectedFileEntries.length === 0) {
      toast.error("No files selected for upload");
      setIsLoading(false);
      return;
    }

    // Validate file formats
    const validFormats = [".png", ".jpg", ".jpeg", ".pdf"];
    const invalidFiles = selectedFileEntries.filter(([index]) => {
      const file = files[index];
      const fileExtension = `.${file.name.split(".").pop().toLowerCase()}`;
      return !validFormats.includes(fileExtension);
    });

    if (invalidFiles.length > 0) {
      toast.error("Only .png, .jpg, .jpeg, and .pdf formats are allowed.");
      setIsLoading(false);
      return;
    }

    // Validate that all selected files have a document type
    const newValidationErrors = {};
    selectedFileEntries.forEach(([index, fileDetails]) => {
      if (
        !fileDetails.type ||
        fileDetails.type === "Document type" ||
        fileDetails.type === "Global Document type" ||
        fileDetails.type.trim() === ""
      ) {
        newValidationErrors[index] = true;
      }
    });

    if (Object.keys(newValidationErrors).length > 0) {
      setValidationErrors(newValidationErrors);
      setIsLoading(false);
      return;
    }

    try {
      // Initialize uploadProgress state for selected files
      const initialProgress = {};
      selectedFileEntries.forEach(([index]) => {
        initialProgress[index] = { progress: 0, status: "uploading" };
      });
      setUploadProgress(initialProgress);

      // Array to store upload results
      const uploadResults = [];

      // Create an array of upload promises
      const uploadPromises = selectedFileEntries.map(([index, fileDetails]) => {
        return new Promise(async (resolve) => {
          const file = files[index];
          const fileType =
            fileDetails.type === "Document type" ||
            fileDetails.type === "Global Document type"
              ? "INVOICE"
              : fileDetails.type?.toUpperCase() || "INVOICE";

          const formData = new FormData();
          formData.append("file", file);
          formData.append("file_type", fileType);
          formData.append("owner", ownerId);
          formData.append(
            "folder",
            selectedFolder !== "" ? selectedFolder.id : ""
          );

          const formattedFileSize = formatFileSize(file.size);
          formData.append("file_size", formattedFileSize);

          try {
            const response = await axios.post(
              `${process.env.REACT_APP_BACKEND_URL}/uploads/files/upload/`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
                onUploadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  );
                  setUploadProgress((prev) => ({
                    ...prev,
                    [index]: {
                      ...prev[index],
                      progress: percentCompleted,
                    },
                  }));
                },
              }
            );

            if (response?.data) {
              setUploadProgress((prev) => ({
                ...prev,
                [index]: { ...prev[index], status: "success" },
              }));
              fetchData();
              uploadResults.push({ index, status: "success" });
              resolve();
            } else {
              setUploadProgress((prev) => ({
                ...prev,
                [index]: { ...prev[index], status: "error" },
              }));
              uploadResults.push({ index, status: "error" });
              resolve();
            }
          } catch (error) {
            console.error(`Error uploading file ${file.name}:`, error);
            setUploadProgress((prev) => ({
              ...prev,
              [index]: { ...prev[index], status: "error" },
            }));
            uploadResults.push({ index, status: "error" });
            resolve();
          }
        });
      });

      // Wait for all uploads to complete
      await Promise.all(uploadPromises);

      // Determine if all uploads were successful
      const allSuccess = uploadResults.every(
        (result) => result.status === "success"
      );

      if (allSuccess) {
        // toast.success("All files uploaded successfully");
        closeUploadModal();
        toast(
          "Your uploaded files have been added to the queue. It will take some time to extract them.",
          {
            duration: 3000,
          }
        );
        fetchData();
        fetchFileStatus();
      } else {
        toast.error("Some files failed to upload");
      }

      setSelectedFiles({});
    } catch (error) {
      console.error("Error uploading files:", error);
      toast.error("Error uploading files");
    } finally {
      setIsLoading(false);
    }
  };

  const areAllSelected =
    files.length > 0 &&
    Object.keys(selectedFiles).length === files.length &&
    Object.values(selectedFiles).every((file) => file.selected);

  // Styles for modal
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "60vw",
      height: "80vh",
      maxWidth: "90vw",
      padding: "20px",
      borderRadius: "10px",
      backgroundColor: "#f3f4f6",
      position: "relative",
      overflowY: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const handleRemoveFile = (index) => {
    const updatedFiles = fileList.filter((_, fileIndex) => fileIndex !== index);
    setFileList(updatedFiles);
    setFiles(updatedFiles);

    const updatedSelectedFiles = { ...selectedFiles };
    delete updatedSelectedFiles[index];

    setSelectedFiles(updatedSelectedFiles);
    setValidationErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[index];
      return newErrors;
    });
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    const newFileList = [...fileList, ...selectedFiles];
    setFileList(newFileList);
    setFiles(newFileList);
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <Modal
      isOpen={isUploadModalOpen}
      onRequestClose={closeUploadModal}
      contentLabel="Upload Modal"
      style={customStyles}
      ariaHideApp={false}
    >
      <button
        onClick={closeUploadModal}
        className="absolute top-2 right-2 text-gray-500 hover:text-red-700 focus:outline-none"
        title="Close"
      >
        <RxCross2 size={24} />
      </button>
      <div className="p-4 raleway-regular">
        <div className="mb-4 flex justify-between flex-wrap">
          <div>
            <input
              type="checkbox"
              id="selectAll"
              name="selectAll"
              onChange={handleSelectAll}
              checked={areAllSelected}
              disabled={isLimitExceeded}
            />
            <label htmlFor="selectAll" className="ml-2">
              Select all documents
            </label>
          </div>

          <div className="flex gap-4">
            <div>
              <input
                id="fileInput"
                type="file"
                multiple
                className="hidden"
                onChange={handleFileChange}
              />
              <button
                className="flex items-center space-x-2 border border-blue-500 text-blue-500 py-2 px-4 rounded-lg cursor-pointer hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out shadow-md"
                onClick={triggerFileInput}
              >
                <IoMdCloudUpload className="text-2xl" />
                <span className="font-medium">Upload file(s)</span>
              </button>
            </div>

            <div className="flex items-center mt-2 sm:mt-0">
              <button
                onClick={openModal}
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
              >
                {selectedFolder ? selectedFolder.name : "Select Folder"}
              </button>

              <FolderTreeModal
                isOpenModal={isOpenModal}
                closeModal={closeModal}
                folders={folders}
                setSelectedFolder={setSelectedFolder}
              />

              <button
                className="bg-blue-300 border border-gray-400 px-2 py-1 rounded ml-2"
                onClick={openNewFolderModal}
                title="Add New Folder"
                disabled={isLimitExceeded}
              >
                <IoMdAdd size={20} />
              </button>
            </div>
            <div className="flex items-center mt-2 sm:mt-0">
              <select
                className={`border px-2 py-1 rounded ${
                  !globalDocumentType && "border-gray-400"
                }`}
                value={globalDocumentType}
                onChange={handleGlobalDocumentTypeChange}
                disabled={isLimitExceeded}
              >
                <option value="">Select Document type</option>
                <option value="INVOICE">Invoice</option>
                <option value="RECEIPT">Receipt</option>
                {/* Add more document types as needed */}
              </select>
            </div>
          </div>
        </div>

        {/* Display inline message if limit is exceeded */}
        {isLimitExceeded && (
          <div className="mb-4 p-4 bg-red-100 text-red-700 rounded">
            You can upload up to {bulkUploadLimit} files at a time. Upgrade your
            plan to upload more files at once.
          </div>
        )}

        <div className="min-h-[50vh]">
          {files.map((file, index) => (
            <div key={index} className="mb-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id={`file-${index}`}
                    name={`file-${index}`}
                    checked={selectedFiles[index]?.selected || false}
                    onChange={() => handleFileSelect(index)}
                    disabled={isLimitExceeded}
                  />
                  <label htmlFor={`file-${index}`} className="ml-2">
                    {file.name}
                  </label>
                </div>
                <div className="flex items-center">
                  <button
                    className="bg-gray-200 border border-gray-400 px-4 py-1 rounded"
                    onClick={() => handlePreviewClick(file)}
                    title="Preview"
                    disabled={isLimitExceeded}
                  >
                    Preview
                  </button>
                  <div className="relative">
                    <select
                      className={`border px-2 py-1 rounded ml-2 ${
                        validationErrors[index]
                          ? "border-red-500"
                          : "border-gray-400"
                      }`}
                      value={selectedFiles[index]?.type || ""}
                      onChange={(e) => {
                        setSelectedFiles((prevSelectedFiles) => ({
                          ...prevSelectedFiles,
                          [index]: {
                            ...prevSelectedFiles[index],
                            type: e.target.value,
                          },
                        }));
                        if (e.target.value) {
                          setValidationErrors((prev) => {
                            const newErrors = { ...prev };
                            delete newErrors[index];
                            return newErrors;
                          });
                        }
                      }}
                      disabled={isLimitExceeded}
                    >
                      <option value="">Document type</option>
                      <option value="INVOICE">Invoice</option>
                      <option value="RECEIPT">Receipt</option>
                      {/* Add more document types as needed */}
                    </select>
                    {validationErrors[index] && (
                      <span className="absolute left-full ml-2 text-red-500 text-sm">
                        *
                      </span>
                    )}
                  </div>
                  {/* Remove file button */}
                  <button
                    className="ml-4 bg-red-700 text-white w-6 h-6 rounded-full hover:bg-red-800 flex justify-center items-center transition duration-300 ease-in-out shadow-lg hover:shadow-2xl transform hover:scale-110"
                    onClick={() => handleRemoveFile(index)}
                    title="Remove file"
                    // disabled={isLimitExceeded}
                  >
                    <span>
                      <LuMinusCircle size={25} />
                    </span>
                  </button>
                </div>
              </div>

              {/* Display progress bar and status indicators */}
              {uploadProgress[index] && (
                <div className="mt-2">
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                      className={`h-2.5 rounded-full ${
                        uploadProgress[index].status === "error"
                          ? "bg-red-600"
                          : "bg-blue-600"
                      }`}
                      style={{ width: `${uploadProgress[index].progress}%` }}
                    ></div>
                  </div>
                  <div className="flex items-center justify-between mt-1">
                    <span className="text-sm">
                      {uploadProgress[index].progress}%
                    </span>
                    {uploadProgress[index].status === "success" && (
                      <FaCheckCircle className="text-green-500" />
                    )}
                    {uploadProgress[index].status === "error" && (
                      <FaTimesCircle className="text-red-500" />
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex justify-center space-x-5 mt-4">
          <button
            className="bg-gray-200 border border-gray-400 px-4 py-2 rounded hover:bg-gray-300 disabled:opacity-50"
            onClick={handleUpload}
            disabled={isLoading || isLimitExceeded}
          >
            {isLoading ? "Uploading..." : "Upload"}
          </button>
          <button
            className="bg-gray-200 border border-gray-400 px-4 py-2 rounded hover:bg-gray-300"
            onClick={closeUploadModal}
            disabled={isLoading}
          >
            Close
          </button>
        </div>
      </div>
      <FilePreviewModal
        isFilePreviewModalOpen={isPreviewModalOpen}
        closeFilePreviewModal={closePreviewModal}
        previewModalContent={previewModalContent}
      />
      <NewFolderModal
        isNewFolderModalOpen={isNewFolderModalOpen}
        closeNewFolderModal={closeNewFolderModal}
        parentFolderId={null}
      />
    </Modal>
  );
};

export default UploadModal;
