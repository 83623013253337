import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import toast from "react-hot-toast";
import Spinner from "../components/Spinner";
import axios from "axios";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";

const MyAccount = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailVerification, setEmailVerification] = useState(true);

  useEffect(() => {
    const fetchUserDataFromLocalStorage = () => {
      try {
        const authData = JSON.parse(localStorage.getItem("auth"));

        if (authData && authData.user) {
          const { user } = authData;
          const { is_verified } = user || {};
          setFormData({
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
          });
          setEmailVerification(is_verified || false);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching user data from localStorage:", error);
        setIsLoading(false);
      }
    };

    fetchUserDataFromLocalStorage();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      if (!token) {
        toast.error("Access token not found. Please log in.");
        setIsSubmitting(false);
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/user/update/`,
        {
          first_name: formData.first_name,
          last_name: formData.last_name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("User data updated successfully");

        const updatedAuthData = JSON.parse(localStorage.getItem("auth"));
        if (updatedAuthData && updatedAuthData.user) {
          updatedAuthData.user.first_name = formData.first_name;
          updatedAuthData.user.last_name = formData.last_name;
          localStorage.setItem("auth", JSON.stringify(updatedAuthData));
        }
      } else {
        console.error("Failed to update user data");
        toast.error("Failed to update user data");
      }
    } catch (error) {
      console.error("Error updating user data:", error);
      toast.error("Error updating user data");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 raleway-regular">
      <Header />
      <div className="flex justify-center items-center py-12">
        <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
          <h2 className="text-2xl font-semibold text-center mb-6">
            My Account
          </h2>
          {isLoading ? (
            <p className="text-center text-gray-600">
              <Spinner />
            </p>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="first_name"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                  placeholder="Enter your first name"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="last_name"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                  placeholder="Enter your last name"
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  <span className="flex gap-2">
                    <span>Email</span>
                    <span className="flex justify-center items-center">
                      {emailVerification ? (
                        <FiCheckCircle className="text-green-500" size={12} />
                      ) : (
                        <FaTimesCircle className="text-red-500" size={12} />
                      )}
                    </span>
                  </span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  readOnly
                  className="w-full px-3 py-2 border border-gray-300 rounded bg-gray-100 focus:outline-none"
                  placeholder="Email is unchangeable"
                />
              </div>
              <div className="flex items-center justify-center">
                <button
                  type="submit"
                  className={`bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out ${
                    isSubmitting
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-blue-600"
                  }`}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Updating..." : "Update"}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
