import React, { useContext, useState, useRef } from "react";
import logo from "../assets/2.png";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../AuthContext";
import toast from "react-hot-toast";

const VerifyLoginOTP = () => {
  const { email } = useParams();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("info");
  const navigate = useNavigate();
  const { login, isLoggedIn } = useContext(AuthContext);

  // Refs for each OTP input field
  const otpRefs = useRef([]);

  // Function to handle OTP input change
  const handleChange = (value, index) => {
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Automatically focus on the next input field if a digit is entered
    if (value !== "" && index < 5) {
      otpRefs.current[index + 1].focus();
    }
  };

  // Function to handle OTP verification
  const handleVerifyOtp = async () => {
    const fullOtp = otp.join("");
    if (fullOtp.length !== 6) {
      setMessage("Please enter a 6-digit OTP.");
      setMessageType("error");
      return;
    }

    setIsVerifying(true);
    setMessage("");

    try {
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/user/verify-login-otp/`;

      const response = await axios.post(apiUrl, {
        email,
        otp_code: fullOtp,
      });

      if (response.status === 200 && response?.data) {
        setMessage("OTP verified successfully!");
        setMessageType("success");
        login(JSON.stringify(response?.data));
        if (response?.data?.user?.is_first_login) {
          navigate("/company-info");
        } else {
          navigate("/");
        }
        toast.success("Login Successfully");
      } else {
        setMessage("Verification failed. Please try again.");
        setMessageType("error");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error(error?.response?.data?.error);
      setMessage("An error occurred while verifying OTP. Please try again.");
      setMessageType("error");
    }

    setIsVerifying(false);
  };

  // Function to handle resending the verification email
  const handleResendVerificationEmail = async () => {
    setIsResending(true);
    setMessage("");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/login-with-otp/`,
        {
          email,
        }
      );

      if (response.status === 200) {
        setMessage("Verification email resent successfully!");
        setMessageType("success");
      } else {
        setMessage("Failed to resend verification email. Please try again.");
        setMessageType("error");
      }
    } catch (error) {
      console.error("Error resending verification email:", error);
      setMessage(
        "An error occurred while resending verification email. Please try again."
      );
      setMessageType("error");
    }

    setIsResending(false);
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="min-h-screen flex justify-center items-center bg-gray-100 raleway-regular">
      <div className="w-full max-w-md md:max-w-lg lg:max-w-2xl bg-[#191D55] text-white rounded-lg shadow-lg p-6 md:p-8 lg:p-10 flex flex-col justify-evenly items-center m-2">
        <img src={logo} alt="Logo" className="h-16 md:h-20 mb-4 ml-4" />
        <h2 className="text-xl md:text-2xl font-bold mb-4 text-center">
          Verify OTP
        </h2>
        <div className="w-full text-center">
          <p>
            A verification OTP has been sent to <strong>{email}</strong>.
          </p>
        </div>
        <div className="w-full text-center mt-2">
          <p>
            Please enter the OTP received in your email to log in to your
            account.
          </p>
        </div>

        <div className="w-full flex items-center justify-center space-x-2 mt-4">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleChange(e.target.value, index)}
              ref={(el) => (otpRefs.current[index] = el)}
              className="w-10 p-2 bg-gray-200 text-gray-800 rounded text-center"
              disabled={isVerifying}
            />
          ))}
        </div>

        <button
          onClick={handleVerifyOtp}
          className="mt-4 bg-white text-[#383B43] rounded-full px-6 py-2 font-semibold shadow hover:bg-gray-200 transition duration-200"
          disabled={isVerifying}
        >
          {isVerifying ? "Verifying..." : "Verify"}
        </button>

        <button
          onClick={handleResendVerificationEmail}
          className="mt-4 bg-white text-[#383B43] rounded-full px-6 py-2 font-semibold shadow hover:bg-gray-200 transition duration-200"
          disabled={isResending}
        >
          {isResending ? "Resending..." : "Resend OTP"}
        </button>

        {message && (
          <p
            className={`mt-4 text-sm ${
              messageType === "error" ? "text-red-500" : "text-green-500"
            }`}
          >
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default VerifyLoginOTP;
