import React, { useState } from "react";
import toast from "react-hot-toast";

function CustomFieldModal({ isOpen, onClose, onSave }) {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSave = async () => {
    if (key.trim() === "" || value.trim() === "") {
      toast.error("Both key and value are required.");
      return;
    }

    setIsSubmitting(true);
    try {
      await onSave({ key, value });
      setKey("");
      setValue("");
      onClose();
      // toast.success("Custom field added successfully!");
    } catch (error) {
      toast.error("Failed to add custom field. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 raleway-regular">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-lg font-semibold mb-4">Add Custom Field</h2>
        <input
          type="text"
          placeholder="Key"
          className="w-full mb-4 border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
          value={key}
          required
          onChange={(e) => setKey(e.target.value)}
        />
        <input
          type="text"
          placeholder="Value"
          className="w-full mb-4 border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
          value={value}
          required
          onChange={(e) => setValue(e.target.value)}
        />
        <div className="flex justify-end gap-4">
          <button
            className="bg-white text-red-500 py-2 px-4 border border-red-500 rounded focus:outline-none focus:ring-2 focus:ring-red-500 hover:bg-red-500 hover:text-white transition duration-300 ease-in-out"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className={`bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out ${
              isSubmitting ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={handleSave}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Adding..." : "Add"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CustomFieldModal;
