import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import CustomFieldModal from "./CustomFieldModal";
import { toast } from "react-hot-toast";
import { AuthContext } from "../AuthContext";
import { RxCross2 } from "react-icons/rx";
import { IoDuplicate } from "react-icons/io5";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

function VisualizedData({ responseData, previewModalContent }) {
  const [editableData, setEditableData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { fetchData } = useContext(AuthContext);

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [isConfirmFieldDeleteModalOpen, setIsConfirmFieldDeleteModalOpen] =
    useState(false);
  const [
    isConfirmFieldDeleteTypeTwoeModalOpen,
    setIsConfirmFieldDeleteTypeTwoModalOpen,
  ] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(null);
  const [deleteFieldIndex, setDeleteFieldIndex] = useState(null);
  const [deleteFieldData, setDeleteFieldData] = useState({
    parentKey: null,
    sectionIndex: null,
    entryIndex: null,
  });

  useEffect(() => {
    if (responseData && Object.keys(responseData).length > 0) {
      setEditableData(responseData);
    } else {
      setEditableData({});
    }
  }, [responseData, previewModalContent]);

  const handleAddCustomField = (field) => {
    let updatedData;

    if (Array.isArray(editableData?.items)) {
      updatedData = {
        ...editableData,
        [field.key]: field.value,
      };
    } else {
      const firstSectionKey = Object.keys(editableData)[1];
      updatedData = {
        ...editableData,
        [firstSectionKey]: [
          ...editableData[firstSectionKey],
          { data: [[field.key, field.value]] },
        ],
      };
    }

    setEditableData(updatedData);
    handleSaveData(updatedData);
  };

  const handleKeyChange = (oldKey, newKey) => {
    if (oldKey === newKey) return;
    if (Object.keys(editableData).includes(newKey)) {
      toast.error("Key already exists.");
      return;
    }
    const entries = Object.entries(editableData);
    const index = entries.findIndex(([key, _]) => key === oldKey);
    if (index === -1) return;
    entries[index][0] = newKey;
    const newData = Object.fromEntries(entries);
    setEditableData(newData);
  };

  const handleConfirmDeleteField = () => {
    setEditableData((prevData) => {
      const newData = { ...prevData };
      delete newData[deleteFieldIndex];
      return newData;
    });
    setIsConfirmFieldDeleteModalOpen(false);
  };

  const handleDeleteField = (keyToDelete) => {
    setDeleteFieldIndex(keyToDelete);
    setIsConfirmFieldDeleteModalOpen(true);
  };

  const handleDataChange = (key, value) => {
    setEditableData((prevData) => ({ ...prevData, [key]: value }));
  };

  // Handle changing nested data (if any)
  const handleNestedDataChange = (
    parentKey,
    sectionIndex,
    entryIndex,
    value
  ) => {
    setEditableData((prevData) => {
      const updatedData = { ...prevData };
      const sections = [...updatedData[parentKey]];
      const section = { ...sections[sectionIndex] };
      section.data = [...section.data];
      section.data[entryIndex] = [section.data[entryIndex][0], value];
      sections[sectionIndex] = section;
      updatedData[parentKey] = sections;
      return updatedData;
    });
  };

  // Handle saving/updating data to the backend
  const handleSaveData = async (data = editableData) => {
    setIsSubmitting(true);
    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      if (!token) {
        toast.error("Access token not found. Please log in.");
        setIsSubmitting(false);
        return;
      }

      if (!previewModalContent?.id) {
        toast.error("File ID is missing.");
        setIsSubmitting(false);
        return;
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/files/${previewModalContent.id}/update/`,
        {
          information: data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Data updated successfully");
        const bulkReviewContent =
          JSON.parse(localStorage.getItem("bulkReviewContent")) || [];
        const updatedBulkReviewContent = bulkReviewContent.map((file) => {
          if (file.id === previewModalContent.id) {
            return {
              ...file,
              information: response.data.information,
            };
          }
          return file;
        });
        localStorage.setItem(
          "bulkReviewContent",
          JSON.stringify(updatedBulkReviewContent)
        );
        fetchData();
        setEditableData(response.data.information);
      } else {
        toast.error("Failed to update data.");
      }
    } catch (error) {
      toast.error("Error updating data. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRowDataChange = (rowIndex, field, value) => {
    setEditableData((prevData) => {
      const newItems = [...(prevData.items || [])];
      newItems[rowIndex] = {
        ...newItems[rowIndex],
        [field]: value,
      };
      return { ...prevData, items: newItems };
    });
  };

  const handleDeleteRow = (rowIndex) => {
    setDeleteRowIndex(rowIndex);
    setIsConfirmDeleteModalOpen(true);
  };

  const handleConfirmDeleteRow = () => {
    const newItems = [...(editableData.items || [])];
    newItems.splice(deleteRowIndex, 1);

    setEditableData((prevData) => ({
      ...prevData,
      items: newItems,
    }));

    setIsConfirmDeleteModalOpen(false);
  };

  const handleDuplicateRow = (rowIndex) => {
    setEditableData((prevData) => {
      const newItems = [...(prevData.items || [])];
      const duplicatedRow = { ...newItems[rowIndex] };
      newItems.splice(rowIndex + 1, 0, duplicatedRow);
      return { ...prevData, items: newItems };
    });
  };

  const handleAddRow = () => {
    setEditableData((prevData) => ({
      ...prevData,
      items: [
        ...(prevData.items || []),
        { description: "", quantity: 0, subtotal: 0, unit_price: 0 },
      ],
    }));
  };

  const columns = [
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      cell: (row, index) => (
        <input
          type="text"
          value={row.description}
          onChange={(e) =>
            handleRowDataChange(index, "description", e.target.value)
          }
          className="w-full border rounded p-1"
        />
      ),
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
      cell: (row, index) => (
        <input
          type="number"
          value={row.quantity}
          onChange={(e) =>
            handleRowDataChange(index, "quantity", e.target.value)
          }
          className="w-full border rounded p-1"
        />
      ),
    },
    {
      name: "Unit Price",
      selector: (row) => row.unit_price,
      sortable: true,
      cell: (row, index) => (
        <input
          type="number"
          value={row.unit_price}
          onChange={(e) =>
            handleRowDataChange(index, "unit_price", e.target.value)
          }
          className="w-full border rounded p-1"
        />
      ),
    },
    {
      name: "Sub Total",
      selector: (row) => row.subtotal,
      sortable: true,
      cell: (row, index) => (
        <input
          type="number"
          value={row.subtotal}
          onChange={(e) =>
            handleRowDataChange(index, "subtotal", e.target.value)
          }
          className="w-full border rounded p-1"
        />
      ),
    },
    {
      name: "",
      cell: (row, index) => (
        <div className="flex gap-2">
          <button
            type="button"
            onClick={() => handleDeleteRow(index)}
            className="text-red-500 hover:text-red-700 focus:outline-none"
            title="Delete Row"
          >
            <RxCross2 size={20} />
          </button>
          <button
            type="button"
            onClick={() => handleDuplicateRow(index)}
            className="text-blue-500 hover:text-blue-700 focus:outline-none"
            title="Duplicate Row"
          >
            <IoDuplicate />
          </button>
        </div>
      ),
    },
  ];

  const formatKey = (key) => {
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Render fields for the first format (information table)
  const renderFirstFormatFields = () => (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {Object.entries(editableData).map(
        ([key, value]) =>
          key !== "items" && (
            <div
              className="mb-2 flex flex-col gap-2 items-center roboto-regular"
              key={key}
            >
              <div className="flex justify-between w-full gap-2">
                {/* Editable Key */}
                <input
                  type="text"
                  className="w-full border-0 rounded p-1 focus:ring focus:ring-blue-200 focus:outline-none"
                  value={key}
                  onChange={(e) => handleKeyChange(key, e.target.value)}
                  title={formatKey(key)}
                />
                {/* Delete Button */}
                <button
                  onClick={() => handleDeleteField(key)}
                  className="text-red-500 hover:text-red-700 focus:outline-none"
                  title="Delete Field"
                >
                  <RxCross2 size={20} />
                </button>
              </div>
              {/* Editable Value */}
              <input
                type="text"
                className="w-full border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
                value={value || ""}
                onChange={(e) => handleDataChange(key, e.target.value)}
                title={formatKey(key)}
              />
            </div>
          )
      )}
    </div>
  );

  // Render fields for the second format (nested information table)
  const renderSecondFormatFields = () => {
    // Collect all entries from section.data into a single list
    const allEntries = [];

    Object.entries(editableData).forEach(([key, sections]) => {
      if (Array.isArray(sections)) {
        sections.forEach((section, sectionIndex) => {
          if (Array.isArray(section.data)) {
            allEntries.push(
              ...section.data.map((entry, entryIndex) => ({
                key,
                sectionIndex,
                entryIndex,
                entry,
              }))
            );
          }
        });
      }
    });

    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {allEntries.map(({ key, sectionIndex, entryIndex, entry }) => (
          <div
            className="mb-2 flex flex-col gap-2 items-center roboto-regular"
            key={`${key}-${sectionIndex}-${entryIndex}`}
          >
            <div className="flex justify-between  gap-2 w-full">
              {/* Editable Key */}
              <input
                type="text"
                className="w-full border-0 rounded p-1 focus:ring focus:ring-blue-200 focus:outline-none"
                value={entry[0]}
                onChange={(e) =>
                  handleNestedKeyChange(
                    key,
                    sectionIndex,
                    entryIndex,
                    e.target.value
                  )
                }
                title={formatKey(entry[0])}
              />
              {/* Delete Button */}
              <button
                onClick={() =>
                  handleDeleteNestedField(key, sectionIndex, entryIndex)
                }
                className="text-red-500 hover:text-red-700 focus:outline-none"
                title="Delete Field"
              >
                <RxCross2 size={20} />
              </button>
            </div>
            {/* Editable Value */}
            <input
              type="text"
              className="w-full border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none"
              value={entry[1] || ""}
              onChange={(e) =>
                handleNestedDataChange(
                  key,
                  sectionIndex,
                  entryIndex,
                  e.target.value
                )
              }
              title={formatKey(entry[0])}
            />
          </div>
        ))}
      </div>
    );
  };

  // Handle changing a nested key
  const handleNestedKeyChange = (
    parentKey,
    sectionIndex,
    entryIndex,
    newKey
  ) => {
    const { key: originalKey, value } =
      editableData[parentKey][sectionIndex].data[entryIndex];
    if (originalKey === newKey) return;
    // Check for duplicate keys in the same section
    const section = editableData[parentKey][sectionIndex];
    const duplicate = section.data.some(
      (entry, idx) => entry[0] === newKey && idx !== entryIndex
    );
    if (duplicate) {
      toast.error("Key already exists in this section.");
      return;
    }
    setEditableData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[parentKey][sectionIndex].data[entryIndex][0] = newKey;
      return updatedData;
    });
  };

  // Handle deleting a nested field
  const handleDeleteNestedField = (parentKey, sectionIndex, entryIndex) => {
    setDeleteFieldData({
      parentKey: parentKey,
      sectionIndex: sectionIndex,
      entryIndex: entryIndex,
    });
    setIsConfirmFieldDeleteTypeTwoModalOpen(true);
  };
  const handleConfirmDeleteFieldTypeTwo = () => {
    setEditableData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[deleteFieldData.parentKey][
        deleteFieldData.sectionIndex
      ].data.splice(deleteFieldData.entryIndex, 1);
      return updatedData;
    });
    setIsConfirmFieldDeleteTypeTwoModalOpen(false);
  };

  return (
    <div className="w-full  bg-red- max-h-[86vh] overflow-auto">
      {/* Heading and Action Buttons */}
      <div className="flex justify-between items-center mb-0 bg-white rounded-t-md p-1 py-2 sticky top-0">
        <h2 className=" text-slate-800 raleway-regular ml-2">
          Visualized Data
        </h2>
        {editableData &&
          typeof editableData === "object" &&
          Object.keys(editableData).length > 0 && (
            <div className="flex gap-2">
              <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className="bg-white text-sky-500 px-3 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out raleway-regular"
              >
                Add Custom Field
              </button>

              <button
                type="button"
                onClick={() => handleSaveData()}
                className={`bg-white text-sky-500 px-3 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out raleway-regular mr-2 ${
                  isSubmitting ? "cursor-not-allowed opacity-50" : ""
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving..." : "Save Changes"}
              </button>
            </div>
          )}
      </div>

      {/* Form Section */}
      <div
        className="rounded-b-lg p-6 bg-white max-h-[80%] overflow-auto shadow-lg mb-2"
        style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 5px 15px" }}
      >
        {/* Conditional Rendering based on editableData */}
        {editableData &&
          typeof editableData === "object" &&
          Object.keys(editableData).length > 0 && (
            <>
              {Array.isArray(editableData?.items)
                ? renderFirstFormatFields()
                : renderSecondFormatFields()}

              {/* Custom Field Modal */}
              <CustomFieldModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSave={handleAddCustomField}
              />
            </>
          )}
      </div>
      {/* Data Table */}
      {Array.isArray(editableData?.items) && editableData.items.length > 0 && (
        <div className="mt-0">
          <div className="flex justify-end items-center">
            <button
              type="button"
              onClick={handleAddRow}
              className="bg-white text-sky-500 px-3 mb-2 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out raleway-regular mx-2"
            >
              Add line item
            </button>
          </div>
          <DataTable
            columns={columns}
            data={editableData.items}
            pagination
            highlightOnHover
            className="lato-regular"
            pointerOnHover
            customStyles={{
              headCells: {
                style: {
                  color: "black",
                  backgroundColor: "white",
                  borderBottom: "2px solid #e2e8f0",
                },
              },
              cells: {
                style: {
                  color: "#2d3748",
                  fontSize: "14px",
                  padding: "10px 12px",
                },
              },
              rows: {
                style: {
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#f7fafc",
                  },
                },
              },
            }}
          />
        </div>
      )}
      <ConfirmDeleteModal
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => setIsConfirmDeleteModalOpen(false)}
        onConfirm={handleConfirmDeleteRow}
      />
      {isConfirmFieldDeleteModalOpen && (
        <ConfirmDeleteModal
          isOpen={isConfirmFieldDeleteModalOpen}
          onClose={() => setIsConfirmFieldDeleteModalOpen(false)}
          onConfirm={handleConfirmDeleteField}
        />
      )}
      {isConfirmFieldDeleteTypeTwoeModalOpen && (
        <ConfirmDeleteModal
          isOpen={isConfirmFieldDeleteTypeTwoeModalOpen}
          onClose={() => setIsConfirmFieldDeleteTypeTwoModalOpen(false)}
          onConfirm={handleConfirmDeleteFieldTypeTwo}
        />
      )}
    </div>
  );
}

export default VisualizedData;
