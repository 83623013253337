import React, { useState } from "react";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

const AddEmployeeModal = ({ isOpen, closeModal, allUsers }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      const userData = JSON.parse(localStorage.getItem("userData"));

      if (!token) {
        toast.error("Access token not found.");
        setLoading(false);
        return;
      }

      if (userData && userData?.company?.name) {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/user/employee-register/`,
          {
            email: formData.email,
            first_name: formData.firstName,
            last_name: formData.lastName,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response?.data) {
          toast.success(
            response.data.message || "Employee added successfully!"
          );
          allUsers(); // Refresh user list after adding employee
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
          });
          closeModal();
        }
      } else {
        toast.error("Please add a company before adding an employee.");
        navigate("/company-settings");
        setLoading(false);
        return;
      }
    } catch (error) {
      console.error("API Error:", error);
      if (error.response?.data?.email) {
        toast.error(`Error: ${error.response.data.email[0]}`);
      } else {
        toast.error("Failed to add the employee. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Add Employee"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center"
      className="bg-white p-6 rounded shadow-md max-w-lg w-full raleway-regular"
    >
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50">
          <ScaleLoader color="#4A90E2" />
        </div>
      )}
      <h2 className="text-2xl font-semibold mb-4">Add Employee</h2>
      {error && <div className="text-red-500 text-center mb-4">{error}</div>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="firstName"
            className="block text-sm font-medium text-gray-700"
          >
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none w-full"
            placeholder="Enter first name"
            required
          />
        </div>
        <div>
          <label
            htmlFor="lastName"
            className="block text-sm font-medium text-gray-700"
          >
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none w-full"
            placeholder="Enter last name"
            required
          />
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none w-full"
            placeholder="Enter email"
            required
          />
        </div>
        <div className="flex gap-4 justify-end">
          <button
            type="button"
            onClick={closeModal}
            className="bg-white text-red-500 py-2 px-4 border border-red-500 rounded focus:outline-none focus:ring-2 focus:ring-red-500 hover:bg-red-500 hover:text-white transition duration-300 ease-in-out"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className={`bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out ${
              loading ? "bg-opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddEmployeeModal;
