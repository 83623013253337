import React, { useContext, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import toast from "react-hot-toast";
import { AuthContext } from "../AuthContext";

const DeleteModal = ({
  isDeleteModalOpen,
  closeDeleteModal,
  fileId,
  setFileId,
}) => {
  const { fetchData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      if (!token) {
        console.error("Access token not found in localStorage.");
        setIsLoading(false);
        return;
      }

      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/files/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 204) {
        toast.success("File deleted successfully!");
        setFileId(null);
        fetchData();
        closeDeleteModal();
      } else {
        toast.error("Unexpected response from server.");
      }
    } catch (error) {
      console.error("Failed to delete the file:", error);
      toast.error("Error deleting file. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="raleway-regular">
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
        overlayClassName="fixed inset-0"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm raleway-regular">
          <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
          <p className="text-gray-700 mb-6">
            Are you sure you want to delete this file?
          </p>
          <div className="flex justify-end gap-4">
            <button
              onClick={closeDeleteModal}
              className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className={`bg-white text-red-500 py-2 px-4 border border-red-500 rounded focus:outline-none focus:ring-2 focus:ring-red-500 hover:bg-red-500 hover:text-white transition duration-300 ease-in-out ${
                isLoading ? "bg-red-400" : "bg-red-500 hover:bg-red-600"
              }`}
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteModal;
