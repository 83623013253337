import React, { useState, useRef, useContext, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { FaSpinner } from "react-icons/fa";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { AuthContext } from "../AuthContext";
import toast from "react-hot-toast";
import axios from "axios";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.js`;

const FileViewer = ({
  fileLink,
  fileType,
  setResponseData,
  previewModalContent,
  isExtracting,
  setIsExtracting,
  setIsLoading,
  isScrolled,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { fetchData, userData } = useContext(AuthContext);
  const containerRef = useRef();
  const [pageDimensions, setPageDimensions] = useState({
    width: 550,
    height: 550,
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  useEffect(() => {
    const updatePageDimensions = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 2200) {
        setPageDimensions({ width: 1000, height: 1000 });
      } else if (screenWidth > 1800) {
        setPageDimensions({ width: 650, height: 700 });
      } else if (screenWidth > 1400) {
        setPageDimensions({ width: 500, height: 800 });
      } else if (screenWidth > 1000) {
        setPageDimensions({ width: 350, height: 400 });
      } else {
        setPageDimensions({ width: 550, height: 550 });
      }
    };

    updatePageDimensions();
    window.addEventListener("resize", updatePageDimensions);

    return () => window.removeEventListener("resize", updatePageDimensions);
  }, []);

  const handleExtract = async () => {
    try {
      setIsExtracting(true);
      setIsLoading(true);

      let blob;
      if (fileLink.startsWith("blob:")) {
        blob = await fetch(fileLink).then((res) => res.blob());
      } else {
        const response = await fetch(fileLink);
        if (!response.ok) throw new Error("Failed to fetch file.");
        blob = await response.blob();
      }

      const mimeType =
        blob.type ||
        (fileLink.endsWith(".pdf") ? "application/pdf" : "image/jpeg");

      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      if (!token) {
        toast.error("Access token not found. Please log in.");
        return;
      }
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/files/${previewModalContent.id}/update/`,
        {
          total_pages: numPages ?? 1,
          extract: true,
          status: "EXTRACTED",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("response.information", response.data.information);
      setResponseData(response.data.information);

      const bulkReviewContent =
        JSON.parse(localStorage.getItem("bulkReviewContent")) || [];
      const updatedBulkReviewContent = bulkReviewContent.map((file) => {
        if (file.id === previewModalContent.id) {
          return {
            ...file,
            information: response.data.information,
          };
        }
        return file;
      });
      localStorage.setItem(
        "bulkReviewContent",
        JSON.stringify(updatedBulkReviewContent)
      );

      // fetchData();
    } catch (error) {
      console.error("Failed to extract data:", error);
      toast.error("Data extraction failed. Please try again.");
    } finally {
      // setIsExtracting(false);
      // setIsLoading(false);
    }

    console.log("Extraction completed");
  };

  return (
    <div className="w-full">
      {["jpg", "jpeg", "png"].includes(fileType) ? (
        <TransformWrapper
          initialScale={1}
          minScale={1}
          maxScale={4}
          wheel={{ step: 0.1 }}
          doubleClick={{ disabled: true }}
        >
          {({ zoomIn, zoomOut, resetTransform, setTransform, ...rest }) => (
            <div className="min-w-full">
              <div className="tools tools flex justify-between items-center mb-0 bg-white rounded-t-md p-1 sticky top-0 w-full">
                <div className="flex gap-2">
                  <button
                    onClick={() => zoomIn()}
                    className="zoom-button bg-white text-sky-500 px-3 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                  >
                    +
                  </button>
                  <button
                    onClick={() => zoomOut()}
                    className="zoom-button bg-white text-sky-500 px-3 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                  >
                    -
                  </button>
                  <button
                    onClick={() => resetTransform()}
                    className="zoom-button bg-white text-sky-500 px-3 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out raleway-regular"
                  >
                    Reset
                  </button>
                </div>
                {!fileLink.startsWith("blob:") && (
                  <button
                    className="bg-white text-sky-500 px-4 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out raleway-regular"
                    type="button"
                    onClick={handleExtract}
                    disabled={
                      isExtracting ||
                      previewModalContent?.status === "NOT_EXTRACTED"
                    }
                  >
                    {isExtracting ? "Extracting..." : "Retry"}{" "}
                  </button>
                )}
              </div>
              <div className="flex flex-wrap justify-center items-center max-h-[86vh] overflow-auto mt-1">
                <TransformComponent>
                  <img
                    src={fileLink}
                    alt="File"
                    style={{
                      width: "90vw",
                      maxHeight: "90vh",
                      objectFit: "contain",
                      marginTop: "0.5rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "zoom-in",
                    }}
                  />
                </TransformComponent>
              </div>
            </div>
          )}
        </TransformWrapper>
      ) : fileType === "pdf" ? (
        <TransformWrapper
          initialScale={1}
          minScale={1}
          maxScale={4}
          wheel={{ step: 0.1 }}
          doubleClick={{ disabled: false }}
        >
          {({ zoomIn, zoomOut, resetTransform, setTransform, ...rest }) => (
            <>
              <div
                className={`tools flex justify-between items-center mb-0 bg-white rounded-t-md p-1 sticky top-0 min-w-full ${
                  isScrolled ? "" : ""
                } `}
              >
                <div className="flex gap-2">
                  <button
                    onClick={() => zoomIn()}
                    className="zoom-button bg-white text-sky-500 px-4 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                  >
                    +
                  </button>
                  <button
                    onClick={() => zoomOut()}
                    className="zoom-button bg-white text-sky-500 px-4 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
                  >
                    -
                  </button>
                  <button
                    onClick={() => resetTransform()}
                    className="zoom-button bg-white text-sky-500 px-4 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out raleway-regular"
                  >
                    Reset
                  </button>
                </div>
                {!fileLink.startsWith("blob:") && (
                  <button
                    className="bg-white text-sky-500 px-4 py-1 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out raleway-regular"
                    type="button"
                    onClick={handleExtract}
                    disabled={
                      isExtracting ||
                      previewModalContent?.status === "NOT_EXTRACTED"
                    }
                  >
                    {isExtracting ? "Extracting..." : "Retry"}{" "}
                  </button>
                )}
              </div>
              <div className="flex flex-wrap justify-center items-center max-h-[86vh] overflow-auto mt-1">
                <TransformComponent>
                  <div
                    style={{
                      // maxHeight: "38rem",
                      width: "full",
                      // overflow: "auto",
                      overflow: "hidden",
                      display: "flex",
                      padding: "0px",
                      marginTop: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "zoom-in",
                    }}
                  >
                    <Document
                      file={fileLink}
                      onLoadSuccess={onDocumentLoadSuccess}
                      loading={
                        <div className="flex justify-center items-center h-full">
                          <FaSpinner className="animate-spin text-2xl text-blue-500" />
                        </div>
                      }
                      error={<p>Failed to load PDF.</p>}
                    >
                      <Page
                        pageNumber={pageNumber}
                        width={pageDimensions.width}
                        height={pageDimensions.height}
                        // renderMode="svg"
                        loading={
                          <div className="flex justify-center items-center h-full">
                            <FaSpinner className="animate-spin text-2xl text-blue-500" />
                          </div>
                        }
                      />
                    </Document>
                  </div>
                </TransformComponent>
                <div className=" w-full ">
                  {numPages > 1 && (
                    <div
                      className="pagination flex justify-around items-center mt-2 pb-8"
                      // style={{ textAlign: "center", marginTop: "10px" }}
                    >
                      <button
                        onClick={() => changePage(-1)}
                        disabled={pageNumber <= 1}
                        className="pagination-button rounded-md bg-slate-200 text-black hover:bg-slate-400 transition disabled:opacity-50 p-1"
                      >
                        <FaAnglesLeft size={15} color="black" />
                      </button>
                      <span className=" p-1 rounded">
                        Page {pageNumber} of {numPages}
                      </span>
                      <button
                        onClick={() => changePage(1)}
                        disabled={pageNumber >= numPages}
                        className="pagination-button rounded-md bg-slate-200 text-black hover:bg-slate-400 transition disabled:opacity-50 p-1"
                      >
                        <FaAnglesRight size={15} color="black" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </TransformWrapper>
      ) : (
        <p style={{ textAlign: "center", paddingTop: "180px" }}>
          Unsupported file type
        </p>
      )}
    </div>
  );
};

export default FileViewer;
