import React, { useContext, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import { AuthContext } from "../AuthContext";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    width: "100%",
    maxWidth: "500px",
    backgroundColor: "#ffffff",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

const NewFolderModal = ({
  isNewFolderModalOpen,
  closeNewFolderModal,
  parentFolderId,
}) => {
  const [folderName, setFolderName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { fetchData } = useContext(AuthContext);

  const handleCreate = async () => {
    if (folderName.trim() === "") {
      setError("Folder name is required.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;
      const userId = JSON.parse(localStorage.getItem("auth"))?.user?.id;

      if (!token || !userId) {
        console.error("Access token or user ID not found in localStorage.");
        return;
      }

      const folderData = {
        name: folderName,
        parent: parentFolderId || null,
        owner: userId,
      };

      // API request to create folder
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/uploads/folders/`,
        folderData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data) {
        fetchData();
      }

      // Update the folder list with the newly created folder
      console.log("Folder created:", response.data);

      handleClose();
    } catch (error) {
      setError("Failed to create folder. Please try again.");
      console.error("Error creating folder:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setFolderName("");
    setError(null);
    closeNewFolderModal();
  };

  return (
    <Modal
      isOpen={isNewFolderModalOpen}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Create New Folder"
      ariaHideApp={false}
    >
      <div className="p-6 max-w-md mx-auto raleway-regular">
        <h2 className="text-xl font-semibold mb-4">Create New Folder</h2>

        <input
          type="text"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
          placeholder="Folder Name"
          className={`w-full border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none ${
            error ? "border-red-500" : "border-gray-300"
          } rounded-md mb-4`}
        />

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <div className="flex justify-end gap-4">
          <button
            onClick={handleClose}
            className="bg-white text-red-500 py-2 px-4 border border-red-500 rounded focus:outline-none focus:ring-2 focus:ring-red-500 hover:bg-red-500 hover:text-white transition duration-300 ease-in-out"
          >
            Cancel
          </button>

          <button
            onClick={handleCreate}
            className={`bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out ${
              loading ? "bg-blue-300" : "bg-sky-400 hover:bg-sky-500"
            }`}
            disabled={loading}
          >
            {loading ? "Creating..." : "Create"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NewFolderModal;
