import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadModal from "./UploadModal";

const Dropzone = () => {
  const [files, setFiles] = useState([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
      setIsUploadModalOpen(true);
    },
  });

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  return (
    <div className="flex justify-center items-center raleway-regular">
      <div
        {...getRootProps()}
        className="border-2 border-dashed border-sky-400 rounded-md p-10 text-center bg-[#FFFFFF] md:min-w-[75vw] max-w-[90vw]"
      >
        <input {...getInputProps()} />
        <button className="bg-sky-400 text-white md:px-16 px-8 py-2 rounded-xl mb-2">
          Choose file
        </button>
        <p className="mb-2">or drag file in here</p>
      </div>
      {isUploadModalOpen && (
        <UploadModal
          isUploadModalOpen={isUploadModalOpen}
          closeUploadModal={closeUploadModal}
          files={files}
          setFiles={setFiles}
        />
      )}
    </div>
  );
};

export default Dropzone;
