import React, { useContext, useState } from "react";
import axios from "axios";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import videoURL from "../assets/layer-hero.mp4";
import logo from "../assets/2.png";
import logo1 from "../assets/logos/1.png";
import logo2 from "../assets/logos/4.png";
import logo3 from "../assets/logos/7.png";
import logo4 from "../assets/logos/8.png";
import logo5 from "../assets/logos/9.png";
import logo6 from "../assets/logos/10.png";
import logo7 from "../assets/logos/11.png";
import logo8 from "../assets/logos/12.png";
import logo9 from "../assets/logos/13.png";
import logo10 from "../assets/logos/14.png";
import logo11 from "../assets/logos/15.png";
import toast from "react-hot-toast";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { ScaleLoader } from "react-spinners";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { login, isLoggedIn } = useContext(AuthContext);
  const [googleLoading, setGoogleLoading] = useState(false);
  const navigate = useNavigate();
  const logos = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
  ];

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handleSuccess = async (credentialResponse) => {
    setGoogleLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/user/social-auth/`,
        {
          token: credentialResponse.credential,
          provider: "google",
        }
      );

      if (response.status === 200 && response?.data) {
        login(JSON.stringify(response?.data));
        if (response?.data?.user?.is_first_login) {
          navigate("/company-info");
        } else {
          navigate("/");
        }
        toast.success("Login Successfully");
      }
    } catch (error) {
      console.error(
        "Login process failed:",
        error.response?.data || error.message
      );
      toast.error(error?.response?.data?.error || "Google Login failed");
    } finally {
      setGoogleLoading(false);
    }
  };

  const handleFailure = (error) => {
    console.log("Login Failed:", error);
    toast.error("Login Failed");
  };

  const url = `${process.env.REACT_APP_BACKEND_URL}/user/login-with-otp/`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post(url, { email: email });

      if (response?.data) {
        toast.success("OTP sent successfully");
        navigate(`/verify-login-otp/${email}`);
      }
    } catch (error) {
      console.error("API call failed:", error.response?.data || error.message);
      toast.error(error.response?.data?.error);
    }
    setIsSubmitting(false);
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="h-screen bg-slate-100 flex flex-wrap raleway-regular">
        <div className="h-screen bg-[#191D55] w-full md:w-1/2 flex flex-col justify-center">
          <div className="flex justify-start pl-4 fixed top-0 left-0">
            <img src={logo} alt="" className="h-16" />
          </div>
          <div className="min-h-[30rem] flex flex-col justify-center items-center">
            <div className="min-h-[20rem] w-[90%] md:w-[38vw] flex flex-col items-center">
              <div className="w-full md:w-[28vw]">
                <p className="text-white lg:text-4xl md:text-2xl text-center">
                  Create your account. No credit card needed.
                </p>
              </div>
              <div className="w-full md:w-[28vw] text-center pt-4">
                <p className="text-white text-lg font-semibold">
                  Extract from your first invoice in a few minutes.
                </p>
              </div>
              <div className="w-full md:w-[28vw] text-center">
                <p className="text-white text-lg font-semibold">
                  Don't have an account yet?{" "}
                  <Link
                    to="/register"
                    className="bg-slate-200 text-black p-0.5 rounded-md"
                  >
                    Register
                  </Link>
                </p>
              </div>
              <div className="pt-6">
                {googleLoading ? (
                  <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50">
                    <ScaleLoader color="#4A90E2" />
                  </div>
                ) : (
                  <GoogleLogin
                    onSuccess={handleSuccess}
                    onError={handleFailure}
                    render={(renderProps) => (
                      <button
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        className="google-login-button bg-white text-black rounded-full px-6 py-2 font-semibold shadow-md hover:bg-gray-200 transition duration-200"
                      >
                        Sign up with Google
                      </button>
                    )}
                  />
                )}
              </div>
              <div className="pt-4 flex items-center w-full md:w-[25rem]">
                <hr className="flex-grow border-gray-300" />
                <span className="px-4 text-gray-100">OR</span>
                <hr className="flex-grow border-gray-300" />
              </div>
              <form
                className="md:w-[28rem] w-full flex flex-col justify-center items-center"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col w-full">
                  <label htmlFor="emailFor" className="mb-2 text-white">
                    Email <span className="text-red-500 font-bold">*</span>
                  </label>
                  <input
                    type="email"
                    id="emailFor"
                    value={email}
                    required
                    onChange={handleEmailChange}
                    className="w-full px-4 py-1 border border-b-0 rounded-md border-gray-300 focus:outline-none focus:border-gray-500"
                  />
                </div>
                <button
                  type="submit"
                  className="rounded-md bg-gray-500 w-full mt-4 py-2 text-white font-bold"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Login"}
                </button>
              </form>
              <div className="w-full md:w-[28vw] text-center pt-4 mb-4">
                <p className="text-center text-sm font-semibold text-white">
                  By signing up, you are creating a DocExtractor account, and
                  you agree to DocExtractor's Terms of Use and Privacy Policy
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden md:flex h-screen bg-white w-full md:w-1/2 flex-col p-8 space-y-2">
          <video
            src={videoURL}
            className="h-[6rem] md:h-[12rem] xl2:h-[16rem] rounded-3xl"
            autoPlay
            loop
            muted
            playsInline
          ></video>

          <div className="w-full">
            <div className="p-4 pt-0">
              <p className="text-black">
                Let us show you how InvoiceExtractor can help..
              </p>
            </div>
            <div className="p-4 pt-0">
              <div className="text-black relative pl-4">
                <span className="absolute left-0 top-1/2 transform -translate-y-1/2">
                  •
                </span>
                AI-powered, cloud-native intelligent document processing with a
                96% average data accuracy rate.
              </div>
            </div>
            <div className="p-4 pt-0">
              <div className="text-black relative pl-4">
                <span className="absolute left-0 top-1/2 transform -translate-y-1/2">
                  •
                </span>
                Streamline your validation workflows, increase productivity, and
                minimize errors.
              </div>
            </div>
            <div className="p-4 py-0">
              <div className="text-black relative pl-4">
                <span className="absolute left-0 top-1/2 transform -translate-y-1/2">
                  •
                </span>
                Low-code, template-free solution with an intuitive UI and
                ability to scale to your needs.
              </div>
            </div>
          </div>
          <div className="w-full">
            <p className="text-sm text-black font-bold pl-3">Trusted by</p>
            <div className="grid grid-cols-6 mt-2 gap-0">
              {logos.map((logo, index) => (
                <div key={index} className="flex justify-center items-center">
                  <img
                    src={logo}
                    alt={`Logo ${index + 1}`}
                    className="w-16 h-16"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default LoginPage;
