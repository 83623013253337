import React, { useState } from "react";
import axios from "axios";
import { ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import Modal from "react-modal";

const ContactTeamModal = ({ isOpen, closeModal }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    reasonForContact: "",
  });
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const token = JSON.parse(localStorage.getItem("auth"))?.access;

      if (!token) {
        toast.error("Access token not found.");
        setLoading(false);
        return;
      }

      const formDataToSend = new FormData();
      formDataToSend.append("first_name", formData.firstName);
      formDataToSend.append("last_name", formData.lastName);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("phone_number", formData.phoneNumber);
      formDataToSend.append("reason_for_connect", formData.reasonForContact);

      if (file) {
        formDataToSend.append("attachment", file);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/subscription/custom-subscription/`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data) {
        toast(response?.data?.detail, { duration: 5000 });
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          reasonForContact: "",
        });
        setFile(null);
        closeModal();
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Failed to submit the request. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Get a Quote"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center"
      className="bg-white p-6 rounded shadow-md max-w-lg w-full max-h-[90vh] overflow-auto raleway-regular"
    >
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50">
          <ScaleLoader color="#4A90E2" />
        </div>
      )}
      <h2 className="text-2xl font-semibold mb-4">Contact Support Team</h2>
      {error && <div className="text-red-500 text-center mb-4">{error}</div>}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="firstName"
            className="block text-sm font-medium text-gray-700"
          >
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none w-full"
            placeholder="Enter first name"
            required
          />
        </div>
        <div>
          <label
            htmlFor="lastName"
            className="block text-sm font-medium text-gray-700"
          >
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none w-full"
            placeholder="Enter last name"
            required
          />
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none w-full"
            placeholder="Enter email"
            required
          />
        </div>
        <div>
          <label
            htmlFor="phoneNumber"
            className="block text-sm font-medium text-gray-700"
          >
            Phone Number
          </label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none w-full"
            placeholder="Enter phone number"
            required
          />
        </div>
        <div>
          <label
            htmlFor="reasonForContact"
            className="block text-sm font-medium text-gray-700"
          >
            Reason for Contact
          </label>
          <textarea
            id="reasonForContact"
            name="reasonForContact"
            value={formData.reasonForContact}
            onChange={handleChange}
            className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none w-full max-h-[8rem]"
            placeholder="Enter reason for contact"
            required
          />
        </div>
        <div>
          <label
            htmlFor="attachment"
            className="block text-sm font-medium text-gray-700"
          >
            Attach a File (optional)
          </label>
          <input
            type="file"
            id="attachment"
            name="attachment"
            onChange={handleFileChange}
            className="border rounded p-2 focus:ring focus:ring-blue-200 focus:outline-none w-full"
          />
        </div>
        <div className="flex gap-4 justify-end">
          <button
            type="submit"
            disabled={loading}
            className={`bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out ${
              loading ? "bg-opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
          <button
            type="button"
            onClick={closeModal}
            className="bg-white text-red-500 py-2 px-4 border border-red-500 rounded focus:outline-none focus:ring-2 focus:ring-red-500 hover:bg-red-500 hover:text-white transition duration-300 ease-in-out"
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ContactTeamModal;
