import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import Header from "../components/Header";
import Dropzone from "../components/Dropzone";
import DataTable from "../components/Table";
import { AuthContext } from "../AuthContext";
import { Navigate } from "react-router-dom";

const Dashboard = () => {
  const {
    isLoggedIn,
    searchKeyword,
    setSearchKeyword,
    setStatusFilter,
    setFileTypeFilter,
    setTypeFilter,
    documentType,
    setDocumentType,
    fileTypeFilter,
    statusFilter,
    typeFilter,
    tableData,
    setTableData,
    userData,
    fileStatus,
    setFileStatus,
    fetchFileStatus,
  } = useContext(AuthContext);

  useEffect(() => {
    fetchFileStatus();

    const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);

    socket.onopen = () => {
      console.log("Connected");
    };

    socket.onerror = (error) => {
      console.error("WebSocket Error: ", error);
    };

    socket.onclose = (event) => {
      console.log("Connection closed: ", event);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      // Update the tableData with the new status
      setTableData((prevStatuses) =>
        prevStatuses.map((file) =>
          file.id === data.file_id ? { ...file, status: data.status } : file
        )
      );

      // Update fileStatus based on the new tableData
      setTableData((updatedTableData) => {
        // Calculate the new counts based on the updated tableData
        const newFileStatus = updatedTableData.reduce(
          (acc, file) => {
            if (file.status === "EXTRACTED") {
              acc.completed += 1;
            } else if (file.status === "EXTRACTING") {
              acc.in_process += 1;
            } else if (file.status === "NOT_EXTRACTED") {
              acc.pending += 1;
            }
            return acc;
          },
          { completed: 0, in_process: 0, pending: 0 }
        );

        setFileStatus(newFileStatus);

        // // Check if any file's status is "EXTRACTED" and call userData()
        // if (updatedTableData.some((file) => file.status === "EXTRACTED")) {
        //   userData();
        // }

        return updatedTableData;
      });
    };

    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    };
  }, []);

  useEffect(() => {
    userData();
  }, [fileStatus]);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const handleFilterClick = (type) => {
    setDocumentType(type);
  };

  const handleTypeFilter = (selectedType) => {
    setTypeFilter(selectedType);
  };

  const handleStatusFilter = (selectedStatus) => {
    setStatusFilter(selectedStatus);
  };

  const handleFileTypeFilter = (selectedFileType) => {
    setFileTypeFilter(selectedFileType);
  };

  const handleClearFilter = () => {
    setSearchKeyword("");
    setTypeFilter("All");
    setStatusFilter("All");
    setFileTypeFilter("All");
    setDocumentType("All");
  };

  // Progress calculation
  const totalFiles =
    fileStatus.completed + fileStatus.pending + fileStatus.in_process;
  const completedPercentage = totalFiles
    ? Math.round((fileStatus.completed / totalFiles) * 100)
    : 0;

  return (
    <div>
      <Header classname="" />
      <div className="flex flex-wrap items-center space-x-4 justify-center mb-4 mt-4">
        <div className="flex flex-wrap justify-start items-center md:min-w-[75vw] max-w-[90vw]">
          <h1 className="text-xl font-bold mr-6 px-2">Documents</h1>
          <div className="flex gap-1 overflow-x-auto no-scrollbar md:space-x-4 px-2 p-1">
            <button
              className={` text-sky-500 px-4 py-2 border border-sky-400 rounded-full focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out ${
                documentType === "All"
                  ? "border-2 border-sky-500 text-white bg-sky-400"
                  : "bg-white"
              }`}
              onClick={() => handleFilterClick("All")}
              value={documentType}
            >
              All Documents
            </button>
            <button
              className={` text-sky-500 px-4 py-2 border border-sky-400 rounded-full focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out ${
                documentType === "INVOICE"
                  ? "border-2 border-sky-500 text-white bg-sky-400"
                  : "bg-white"
              }`}
              onClick={() => handleFilterClick("INVOICE")}
              value={documentType}
            >
              Invoices
            </button>
            <button
              className={` text-sky-500 px-4 py-2 border border-sky-400 rounded-full focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out ${
                documentType === "RECEIPT"
                  ? "border-2 border-sky-500 text-white bg-sky-400"
                  : "bg-white"
              }`}
              onClick={() => handleFilterClick("RECEIPT")}
              value={documentType}
            >
              Receipts
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="md:min-w-[75vw] max-w-[90vw] bg-white shadow rounded-lg">
          <div className="flex space-x-4 p-4 justify-between overflow-x-auto no-scrollbar">
            <div className="flex space-x-4">
              <input
                type="text"
                placeholder="Search documents"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                className="px-4 py-2 md:w-[18rem] border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-sky-400"
              />
              <select
                className="px-4 py-2 border md:w-[12rem] border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-sky-400"
                onChange={(e) => handleTypeFilter(e.target.value)}
                value={typeFilter}
              >
                <option value="all">Type: All</option>
                <option value="folder">Folder</option>
                <option value="file">File</option>
              </select>

              <select
                className="px-4 py-2 border md:w-[12rem] border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-sky-400"
                onChange={(e) => handleStatusFilter(e.target.value)}
                value={statusFilter}
              >
                <option value="all">Status: All</option>
                <option value="extracted">Extracted</option>
                <option value="not-extracted">Pending</option>
                <option value="extracting">Extracting</option>
                <option value="failed">Failed</option>
              </select>
              <select
                className="px-4 py-2 border md:w-[12rem] border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-sky-400"
                onChange={(e) => handleFileTypeFilter(e.target.value)}
                value={fileTypeFilter}
              >
                <option value="all">File Type: All</option>
                <option value="invoice">Invoice</option>
                <option value="receipt">Receipt</option>
              </select>
            </div>

            <button
              className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
              onClick={handleClearFilter}
            >
              Clear Filters
            </button>
          </div>
          <div className="flex justify-center p-4">
            <Dropzone />
          </div>
        </div>
      </div>

      <div className="sticky top-16 z-0">
        {/* Progress Bar */}

        {(fileStatus.in_process !== 0 || fileStatus.pending !== 0) && (
          <div className="flex justify-center items-center mb-2 mt-4 ">
            <div className="md:w-[75vw] w-[90vw] bg-white shadow rounded-lg p-4">
              <h2 className="text-lg font-bold mb-2">File Status</h2>
              <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
                <div
                  className="bg-sky-500 h-4 rounded-full"
                  style={{ width: `${completedPercentage}%` }}
                />
              </div>
              <div className="flex justify-between text-sm">
                <p>Completed: {fileStatus.completed}</p>
                <p>In Process: {fileStatus.in_process}</p>
                <p>Pending: {fileStatus.pending}</p>
              </div>
            </div>
          </div>
        )}

        {/* Data Table */}
        <div className="flex justify-center mb-6">
          <div className="md:min-w-[78vw] max-w-[90vw]">
            <DataTable />
          </div>
        </div>
      </div>
      <div className="h-[2rem] bg"></div>
    </div>
  );
};

export default Dashboard;
