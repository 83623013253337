import React, { useState, useEffect, useContext } from "react";
import Modal from "react-modal";
import FileViewer from "./FileViewer";
import VisualizedData from "./VisualizedData";
// import Spinner from "./Spinner";
// import ReactJson from "@vahagn13/react-json-view";
import { FaAnglesRight, FaAnglesLeft, FaCaretDown } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { PropagateLoader } from "react-spinners";
import toast from "react-hot-toast";
import axios from "axios";
import { AuthContext } from "../AuthContext";

const BulkPreviewModal = ({
  isBulkPreviewModalOpen,
  closeBulkPreviewModal,
  bulkPreviewModalContent,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "60vw",
      height: "96vh",
      maxWidth: "96vw",
      padding: "10px",
      borderRadius: "10px",
      backgroundColor: "#f3f4f6",
      position: "relative",
      overflowY: "auto",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    "@media (max-width: 768px)": {
      content: {
        minWidth: "85vw",
        height: "75vh",
        padding: "5px",
        overflowY: "auto",
      },
    },
    "@media (max-width: 480px)": {
      content: {
        minWidth: "95vw",
        height: "65vh",
        padding: "2px",
        overflowY: "auto",
      },
    },
  };

  const [responseData, setResponseData] = useState({});
  const [activeTab, setActiveTab] = useState("visualizedData");
  const [isLoading, setIsLoading] = useState(false);
  const [isExtracting, setIsExtracting] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFileListVisible, setIsFileListVisible] = useState(false);
  const { userData } = useContext(AuthContext);

  const jsonViewTheme = "dark";

  const fileList = bulkPreviewModalContent || [];

  useEffect(() => {
    if (fileList[currentIndex] && fileList[currentIndex].information) {
      setResponseData(fileList[currentIndex].information);
    } else {
      setResponseData({});
    }
  }, [currentIndex, fileList]);

  useEffect(() => {
    const getFileDetails = async () => {
      try {
        const token = JSON.parse(localStorage.getItem("auth"))?.access;
        if (!token) {
          toast.error("Access token not found. Please log in.");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/uploads/files/${fileList[currentIndex]?.id}/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response?.data) {
          if (response?.data?.status === "EXTRACTING") {
            setIsExtracting(true);
            setIsLoading(true);
          } else {
            setIsExtracting(false);
            setIsLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    // Only hit the API if isBulkPreviewModalOpen is true
    if (isBulkPreviewModalOpen && fileList[currentIndex]) {
      getFileDetails();
    }
  }, [fileList[currentIndex], isBulkPreviewModalOpen]);

  const handlePreviousClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < fileList.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleFileSelect = (index) => {
    setCurrentIndex(index);
    setIsFileListVisible(false);
  };

  const getFormat = (item) => {
    const fileExtension = item.file?.split(".").pop().toLowerCase();
    return ["pdf", "jpg", "jpeg", "png"].includes(fileExtension)
      ? fileExtension
      : "unknown";
  };

  useEffect(() => {
    const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);

    socket.onopen = () => {
      console.log("Connected to WebSocket");
    };

    socket.onerror = (error) => {
      console.error("WebSocket Error: ", error);
    };

    socket.onclose = (event) => {
      console.log("Connection closed: ", event);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.file_id === fileList[currentIndex]?.id) {
        if (data?.status === "EXTRACTING") {
          setIsExtracting(true);
          setIsLoading(true);
        } else if (data?.status === "EXTRACTED") {
          setResponseData(data?.info);
          setIsExtracting(false);
          setIsLoading(false);
          userData();
        } else {
          setIsExtracting(false);
          setIsLoading(false);
        }
      }
    };

    return () => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    };
  }, [fileList[currentIndex]]);

  return (
    <Modal
      isOpen={isBulkPreviewModalOpen}
      onRequestClose={closeBulkPreviewModal}
      contentLabel="Bulk Preview Modal"
      style={customStyles}
    >
      <button
        onClick={closeBulkPreviewModal}
        className="fixed top-1 right-2 text-gray-500 hover:text-red-700 focus:outline-none"
      >
        <RxCross2 size={24} />
      </button>
      {fileList.length > 0 && (
        <div className="p-2 py-0.5 flex flex-col raleway-regular">
          <div className="p-2 flex flex-col md:flex-row md:gap-4">
            <div className="basis-2/5 flex-grow md:min-h-[60vh] min-h-[50vh]  flex justify-start items-start">
              <FileViewer
                fileLink={
                  process.env.REACT_APP_BACKEND_URL +
                  fileList[currentIndex].file
                }
                fileType={getFormat(fileList[currentIndex])}
                setResponseData={setResponseData}
                setIsLoading={setIsLoading}
                previewModalContent={fileList[currentIndex]}
                setIsExtracting={setIsExtracting}
                isExtracting={isExtracting}
              />
            </div>
            <div className="basis-4/5 flex-grow min-h-[35vh] p-1">
              <div
                className="flex justify-between items-center bg-[#ffffff] p-2 rounded-t-lg shadow-sm raleway-regular"
                style={{ borderWidth: "1px" }}
              >
                <button
                  className="text-black"
                  onClick={handlePreviousClick}
                  disabled={currentIndex === 0}
                >
                  <span className="flex gap-1">
                    <FaAnglesLeft size={20} />
                    Previous
                  </span>
                </button>
                <div className="flex items-center relative">
                  <span className="text-black mr-2">
                    {fileList[currentIndex]?.file_name
                      ? fileList[currentIndex]?.file_name
                      : fileList[currentIndex].file.split("/").pop()}
                  </span>
                  <FaCaretDown
                    size={20}
                    color="black"
                    className="cursor-pointer"
                    onClick={() => setIsFileListVisible(!isFileListVisible)}
                  />
                  {isFileListVisible && (
                    <div className="absolute top-8 left-0 bg-white border rounded shadow-lg z-10">
                      <ul className="max-h-60 overflow-y-auto">
                        {fileList.map((file, index) => (
                          <li
                            key={index}
                            className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => handleFileSelect(index)}
                          >
                            {file?.file_name
                              ? file?.file_name
                              : file.file.split("/").pop()}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <button
                  className="text-black"
                  onClick={handleNextClick}
                  disabled={currentIndex === fileList.length - 1}
                >
                  <span className="flex gap-1">
                    Next
                    <FaAnglesRight size={20} />
                  </span>
                </button>
              </div>
              <div>
                {/* <div className="custom-pills flex gap-2 justify-around mt-1 flex-wrap">
                  <button
                    onClick={() => setActiveTab("visualizedData")}
                    className={` text-slate-800 py-2 px-4 rounded ${
                      activeTab === "visualizedData"
                        ? "border-b-slate-900 border-2 border-t-0 border-l-0 border-r-0"
                        : ""
                    }`}
                  >
                    Visualized Data
                  </button>
                  <button
                    onClick={() => setActiveTab("standardizedJSON")}
                    className={` text-slate-800 py-2 px-4 rounded ${
                      activeTab === "standardizedJSON"
                        ? "border-b-slate-900 border-2 border-t-0 border-l-0 border-r-0"
                        : ""
                    }`}
                  >
                    Standardized JSON
                  </button>
                </div> */}
                <div className="mt-4">
                  {activeTab === "visualizedData" && (
                    <>
                      {isLoading ? (
                        <div className="text-center mt-20 bg-slate-300">
                          <PropagateLoader color="#4A90E2" />
                        </div>
                      ) : Object.keys(responseData)?.length > 0 ? (
                        <VisualizedData
                          responseData={responseData}
                          previewModalContent={fileList[currentIndex]}
                        />
                      ) : (
                        <div className=" p-2 min-h-[10rem] flex justify-center items-center">
                          No data available
                        </div>
                      )}
                    </>
                  )}
                  {/* {activeTab === "standardizedJSON" && (
                    <>
                      {isLoading ? (
                        <div className="text-center mb-2">
                          <Spinner bdColor="border-slate-800" />
                        </div>
                      ) : (
                        <ReactJson
                          theme={jsonViewTheme}
                          src={responseData}
                          style={{
                            borderRadius: "0.3rem",
                            boxShadow: "rgba(0, 0, 0, 0.05) 0px 5px 15px",
                            padding: "1rem",
                            backgroundColor: "#ffffff",
                            maxHeight: "30rem",
                            overflow: "auto",
                          }}
                        />
                      )}
                    </>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default BulkPreviewModal;
