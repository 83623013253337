import React, { useState } from "react";
import ContactTeamModal from "./ContactTeamModal";

const LimitWarningModal = ({ isOpen, closeModal }) => {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const handleContactClick = () => {
    closeModal();
    setIsContactModalOpen(true);
  };

  return (
    <>
      {isOpen && !isContactModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-bold mb-4 text-center">
              Employee Limit Exceeded
            </h2>
            <p className="mb-6 text-center">
              Contact docextractor support team to increase your employee
              management limit.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={closeModal}
                className="bg-white text-red-500 py-2 px-4 border border-red-500 rounded focus:outline-none focus:ring-2 focus:ring-red-500 hover:bg-red-500 hover:text-white transition duration-300 ease-in-out"
              >
                Cancel
              </button>
              <button
                onClick={handleContactClick}
                className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
              >
                Contact
              </button>
            </div>
          </div>
        </div>
      )}

      {isContactModalOpen && (
        <ContactTeamModal
          isOpen={isContactModalOpen}
          closeModal={() => setIsContactModalOpen(false)}
        />
      )}
    </>
  );
};

export default LimitWarningModal;
