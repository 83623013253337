import React, { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import Header from "../components/Header";
import { BeatLoader } from "react-spinners";
import { formatTimestampDateComponent } from "../components/Helper";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axios from "axios";
import { AuthContext } from "../AuthContext";
import GetAQuoteModal from "../components/GetAQuoteModal";
import { FaCheck, FaCheckCircle } from "react-icons/fa";

const Subscription = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visiblePlans, setVisiblePlans] = useState([0, 4]);
  const [submittingPlanId, setSubmittingPlanId] = useState(null);
  const navigate = useNavigate();
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userData } = useContext(AuthContext);
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const [isTrialPeriod, setIsTrialPeriod] = useState(false);

  const fetchSubscriptionDetails = () => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData) {
        setCancelSubscription(userData?.cancel_subscription);
        let paymentDetails = {
          "Available Credits": userData?.credits_available,
        };
        if (userData?.is_trial_period) {
          paymentDetails["Trial Expired At"] = userData.trial_expired_at
            ? formatTimestampDateComponent(
                "trial_expired_at",
                userData,
                "MMM D, YY"
              )
            : "";
          setIsTrialPeriod(true);
        } else {
          paymentDetails["Plan Name"] = userData?.razorpay_plan_name;
          paymentDetails["Plans Starts At"] =
            userData.razorpay_subscription_start_at
              ? formatTimestampDateComponent(
                  "razorpay_subscription_start_at",
                  userData,
                  "MMM D, YY"
                )
              : "";
          paymentDetails["Plan Ends At"] = userData.razorpay_subscription_end_at
            ? formatTimestampDateComponent(
                "razorpay_subscription_end_at",
                userData,
                "MMM D, YY"
              )
            : "";
        }
        setSubscriptionDetails(paymentDetails);
      } else {
        throw new Error("No user data found in local storage.");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchPlans = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/subscription/razorpay-plans/`
      );
      setPlans(response?.data?.data || []);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchPlans();
    fetchSubscriptionDetails();
  }, []);

  const handleCancelSubscription = async () => {
    setIsModalOpen(true);
  };

  const handleConfirmCancel = async () => {
    setIsModalOpen(false);

    try {
      const authToken = JSON.parse(localStorage.getItem("auth")).access;
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/subscription/cancel-subscription/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Your subscription has been cancelled successfully.");
        userData();
        fetchSubscriptionDetails();
      } else {
        toast.error(
          "There was a problem cancelling your subscription. Please try again."
        );
      }
    } catch (error) {
      alert(
        "An error occurred while processing your request. Please try again later."
      );
    }
  };

  if (loading) {
    return (
      <div className="h-screen flex justify-center items-center">
        <BeatLoader />
      </div>
    );
  }

  const handleNext = () => {
    setVisiblePlans([visiblePlans[0] + 1, visiblePlans[1] + 1]);
  };

  const handlePrev = () => {
    setVisiblePlans([visiblePlans[0] - 1, visiblePlans[1] - 1]);
  };

  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case "INR":
        return "₹";
      case "USD":
        return "$";
      case "EUR":
        return "€";
      default:
        return "";
    }
  };

  const handleSubscribe = async (plan_id) => {
    try {
      setSubmittingPlanId(plan_id);
      const token = JSON.parse(localStorage.getItem("auth"))?.access;

      if (!token) {
        console.error("Access token not found");
        setSubmittingPlanId(null);
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/subscription/razorpay-subscriptions/`,
        { plan_id: plan_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.data?.short_url) {
        navigate("/");
        window.open(response.data.data.short_url, "_blank");
      } else {
        toast.error("Something went wrong, please try again");
      }
    } catch (error) {
      console.log("Error subscribing to the plan:", error);
      toast.error("Error subscribing, please try again");
    } finally {
      setSubmittingPlanId(null);
    }
  };

  // console.log(cancelSubscription);

  return (
    <div className="raleway-regular">
      <Header classname="z-10" />
      <div className="container mx-auto p-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col sm:flex-row sm:justify-between items-center relative p-4">
            <h1 className="text-2xl sm:text-3xl font-bold text-center sm:absolute sm:left-1/2 sm:transform sm:-translate-x-1/2 mb-4 sm:mb-0">
              Subscription Details
            </h1>

            {/* <button
              className="p-2 font-bold sm:ml-auto cursor-pointer     bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
              onClick={() => navigate("/plans")}
            >
              View All Plans
            </button> */}
          </div>
        </motion.div>

        <motion.div
          className="max-w-xl mx-auto bg-white p-6 rounded-lg shadow-lg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="space-y-4">
            {/* {console.log("subscriptionDetails", subscriptionDetails)} */}
            {subscriptionDetails &&
              Object.entries(subscriptionDetails)?.map(([key, value]) => (
                // <>
                <div key={key} className="flex justify-between">
                  <span className="font-bold text-gray-700">
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                    :
                  </span>
                  <span
                    className={`text-gray-600 break-words ${
                      value.length > 50 ? "truncate max-w-xs" : ""
                    }`}
                    title={
                      typeof value === "string" && value.length > 50
                        ? value
                        : ""
                    }
                  >
                    {typeof value === "string" && value.length > 50
                      ? value.slice(0, 50) + "..."
                      : value}
                  </span>
                </div>
              ))}
          </div>

          <motion.div
            className="text-center mt-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {!isTrialPeriod && (
              <button
                onClick={handleCancelSubscription}
                className={`bg-white text-red-500 font-bold py-2 px-4 border border-red-500 rounded focus:outline-none ${
                  cancelSubscription
                    ? ""
                    : "focus:ring-2 focus:ring-red-500 hover:bg-red-500 hover:text-white transition duration-300 ease-in-out"
                } `}
                disabled={cancelSubscription}
              >
                {cancelSubscription
                  ? "Subscription Canceled"
                  : "Cancel Subscription"}
              </button>
            )}
          </motion.div>
        </motion.div>
      </div>
      <div className="text-center my-8">
        <h1 className="text-4xl font-bold">Plans</h1>
      </div>

      <div className="flex items-center justify-center gap-4">
        {visiblePlans[0] > 0 && (
          <button
            onClick={handlePrev}
            className="text-2xl p-2 border rounded-md hover:bg-gray-100 hidden lg:block"
          >
            &#8592;
          </button>
        )}

        <div className="flex flex-wrap flex-col items-center lg:flex-row lg:justify-center gap-8">
          {!isLoading &&
            !error &&
            plans
              .sort((a, b) => a.item.amount - b.item.amount) // Sort plans by price (low to high)
              .slice(visiblePlans[0], visiblePlans[1])
              .map((plan) => (
                <div
                  key={plan.id}
                  className="flex flex-col justify-between border border-black p-6 rounded-md min-h-92 min-w-[20rem]  text-center hover:shadow-lg "
                >
                  <h2 className="text-5xl font-bold mb-4">
                    {getCurrencySymbol(plan.item.currency)}
                    {plan.item.amount / 100}

                    {/* Check if the actual price is available */}
                    {plan?.notes?.actual_price && (
                      <div>
                        {/* Display actual price with strikeout */}
                        <span className="text-2xl font-medium text-gray-500 line-through ml-4">
                          {getCurrencySymbol(plan.item.currency)}
                          {plan?.notes.actual_price}
                        </span>

                        {/* Calculate and display discount percentage */}
                        <span className="text-lg font-medium text-green-500 ml-2">
                          (
                          {Math.round(
                            ((plan?.notes.actual_price -
                              plan.item.amount / 100) /
                              plan?.notes.actual_price) *
                              100
                          )}
                          % off)
                        </span>
                      </div>
                    )}
                  </h2>

                  <p className="text-gray-600 mb-4">
                    Billed {plan.interval} {plan.period}
                  </p>
                  <p className="text-lg mb-2 font-semibold">{plan.item.name}</p>

                  <p className="text-lg mb-2 flex flex-col items-start">
                    {plan.item.description
                      ? plan.item.description
                          .split("~")
                          .filter(Boolean)
                          .map((line, index) => (
                            <span
                              key={index}
                              className="flex items-center mb-2"
                            >
                              <FaCheck className="text-green-500 mr-3" />{" "}
                              {line.trim()}
                            </span>
                          ))
                      : "No description available"}
                  </p>

                  <button
                    className={`${
                      subscriptionDetails?.["Plan Name"] === plan?.item?.name
                        ? "bg-green-500 text-white px-6 py-2 rounded-md cursor-default"
                        : "bg-sky-400 text-white px-6 py-2 rounded-md hover:bg-sky-500"
                    } transition-colors duration-300`}
                    onClick={() => handleSubscribe(plan.id)}
                    disabled={
                      submittingPlanId === plan.id ||
                      subscriptionDetails?.["Plan Name"] === plan?.item?.name
                    }
                  >
                    {subscriptionDetails?.["Plan Name"] === plan?.item?.name
                      ? cancelSubscription
                        ? "Subscription Canceled"
                        : "Subscribed"
                      : submittingPlanId === plan.id
                      ? "Redirecting..."
                      : "Subscribe Now"}
                  </button>
                </div>
              ))}

          {isLoading && (
            <p>
              <BeatLoader />
            </p>
          )}
          {error && <p>Error loading plans: {error.message}</p>}
        </div>

        {visiblePlans[1] < plans.length && (
          <button
            onClick={handleNext}
            className="text-2xl p-2 border rounded-md hover:bg-gray-100 hidden lg:block"
          >
            &#8594;
          </button>
        )}
      </div>

      {/* Footer section */}
      <div className="flex flex-col md:flex-row md:justify-evenly items-center mt-8 mb-5">
        <p className="text-lg font-semibold mb-4">Need a Custom Plan?</p>
        <button
          className="border border-black px-6 py-2 rounded-md hover:bg-gray-200"
          onClick={() => setIsQuoteModalOpen(true)}
        >
          Get a Quote
        </button>
      </div>
      <GetAQuoteModal
        isOpen={isQuoteModalOpen}
        closeModal={() => setIsQuoteModalOpen(false)}
      />
      {/* Confirmation Modal */}
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmCancel}
        title="Cancel Subscription"
        message="Are you sure you want to cancel your subscription? This action cannot be undone."
      />
    </div>
  );
};

export default Subscription;

const ConfirmModal = ({ isOpen, onClose, onConfirm, message, title }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg max-w-sm w-full">
        <div className="p-4">
          <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
          <p className="text-gray-600 mt-2">{message}</p>
        </div>
        <div className="flex justify-end space-x-2 p-4 border-t border-gray-200">
          <button
            className="bg-white text-sky-500 px-4 py-2 border border-sky-400 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 hover:bg-sky-400 hover:text-white transition duration-300 ease-in-out"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-white text-red-500 py-2 px-4 border border-red-500 rounded focus:outline-none focus:ring-2 focus:ring-red-500 hover:bg-red-500 hover:text-white transition duration-300 ease-in-out"
            onClick={onConfirm}
          >
            Yes, Cancel it
          </button>
        </div>
      </div>
    </div>
  );
};
